import React, { Component } from "react";
import { connect } from "react-redux";

class JoTableHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
    };
  }

  componentDidMount() {
    const prixList = this.props.joSelected?.prix_list;
    const alertChecked = prixList?.filter(
      (el) => !!el.boitePresentation?.bp_alert_email
    );
    this.setState({
      allChecked: prixList?.length && alertChecked?.length === prixList?.length,
    });
  }

  render() {
    const rendredTable = this.props.joHeaders;
    const { prixList, handleEditEmailAlert } = this.props;

    const handleCheckEmailAlert = (event) => {
      const emailAlert = !this.state.allChecked;
      prixList?.length &&
        this.setState({ allChecked: event.target.checked }, () => {
          const data = prixList.map(function (elem) {
            return {
              bp_id: elem.boitePresentation.bp_id,
              bp_alert_email: emailAlert ? 1 : 0,
            };
          });
          handleEditEmailAlert({
            bpIdList: data,
          });
        });
    };

    return (
      <thead className="thead dashboard__thead">
        <tr>
          {rendredTable
            .filter((e) => e.checked)
            .map((e, index) => (
              <td id="id" key={index}>
                <p
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {e.label === "alerte email" && this.props.joHeaders && (
                    <input
                      className="checkbox _icon-checkbox"
                      type="checkbox"
                      checked={this.state.allChecked}
                      style={{ margin: " 0px 5px 0px -10px" }}
                      onChange={(e) => handleCheckEmailAlert(e)}
                      name="checkAll"
                    />
                  )}
                  <div className="dashboard__head">{e.label}</div>
                </p>
              </td>
            ))}
          <td />
        </tr>
      </thead>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joSelected: state.JOReducer.joSelected,
  };
};

export default connect(mapStateToProps, {})(JoTableHead);
