import HelmetComponent from "./HelmetComponent";

const NotFound = () => (
  <main className="page">
    <HelmetComponent title="404" />

    <section className="settings settings_line">
      <div className="settings__container _container">
        <div className="settings__wrapper">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h1>Not found</h1>
          </div>
        </div>
      </div>
    </section>
  </main>
);

export default NotFound;
