import {
  ADD_FILE_PRICING_JAPAN,
  DELETE_FILE_PRICING_JAPAN,
  EDIT_FILE_PRICING_JAPAN,
  GET_PRICING_JAPAN_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingJapan: [],
  
};

export default function pricingJapan(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_JAPAN_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingJapan: action.payload,
      });
    }
    case EDIT_FILE_PRICING_JAPAN: {
      return Object.assign({}, state, {
        listPricingJapan: state.listPricingJapan.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

       });
    }
    case ADD_FILE_PRICING_JAPAN: {
      return Object.assign({}, state, {
        listPricingJapan: [action.payload].concat(state.listPricingJapan),
       });
    }

    case DELETE_FILE_PRICING_JAPAN: {
      return Object.assign({}, state, {
        listPricingJapan: [
          ...state.listPricingJapan.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
