/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_IQWIG_FILE_LIST,
  ADD_FILE_IQWIG,
  EDIT_FILE_IQWIG,
  DELETE_FILE_IQWIG,
} from "../../Const/ActionTypes";

const initialState = {
  listIqwig: [],
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_IQWIG_FILE_LIST: {
      return Object.assign({}, state, {
        listIqwig: action.payload,
      });
    }
    case EDIT_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: state.listIqwig.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
       });
    }
    case ADD_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: [action.payload].concat(state.listIqwig),
       });
    }

    case DELETE_FILE_IQWIG: {
      return Object.assign({}, state, {
        listIqwig: [
          ...state.listIqwig.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
