/* eslint import/no-anonymous-default-export: "off" */

import {
    ADD_FILE_BRESIL,
    DELETE_FILE_BRESIL,
    EDIT_FILE_BRESIL,
    GET_BRESIL_FILE_LIST,
  } from "../../Const/ActionTypes";
  
  const initialState = {
      listBresil: [],
    
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_BRESIL_FILE_LIST: {
        return Object.assign({}, state, {
          listBresil: action.payload,
        });
      }
  
      case ADD_FILE_BRESIL: {
        return Object.assign({}, state, {
            listBresil: [action.payload].concat(state.listBresil),
         });
      }
      case EDIT_FILE_BRESIL: {
        return Object.assign({}, state, {
            listBresil: [
            action.payload,
            ...state.listBresil.filter(
              (element) => element.id !== action.payload.id
            ),
          ],
         });
      }
      case DELETE_FILE_BRESIL: {
        return Object.assign({}, state, {
          listCadth: [
            ...state.listBresil.filter((element) => element.id !== action.payload),
          ],
        });
      }
      default:
        return state;
    }
  }
  