import React from "react";

function AgencyLine({ agencyItem, handleChangePermissions }) {
  return (
    <div className="form-settings__columns form-settings__columns_start">
      <div style={{ width: "195px" }}>{agencyItem.agencyLabel}</div>
      <div className="form-settings__column checkbox_container">
        <input
          className="checkbox _icon-checkbox"
          type="checkbox"
          checked={agencyItem.create}
          name="create"
          onChange={(e) => {
            handleChangePermissions(agencyItem.agencyLabel, e);
          }}
        />
      </div>
      <div className="form-settings__column checkbox_container">
        <input
          className="checkbox _icon-checkbox"
          type="checkbox"
          checked={agencyItem.update}
          name="update"
          onChange={(e) => {
            handleChangePermissions(agencyItem.agencyLabel, e);
          }}
        />
      </div>
      <div className="form-settings__column checkbox_container">
        <input
          className="checkbox _icon-checkbox"
          type="checkbox"
          checked={agencyItem.duplicate}
          name="duplicate"
          onChange={(e) => {
            handleChangePermissions(agencyItem.agencyLabel, e);
          }}
        />
      </div>
      <div className="form-settings__column checkbox_container">
        <input
          className="checkbox _icon-checkbox"
          type="checkbox"
          checked={agencyItem.delete}
          name="delete"
          onChange={(e) => {
            handleChangePermissions(agencyItem.agencyLabel, e);
          }}
        />
      </div>
    </div>
  );
}

export default AgencyLine;
