/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_PMDA_FILE_LIST,
  ADD_FILE_PMDA,
  EDIT_FILE_PMDA,
  DELETE_FILE_PMDA,
} from '../../Const/ActionTypes';

const initialState = {
  listPMDA: [],
   
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PMDA_FILE_LIST: {
      return Object.assign({}, state, {
        listPMDA: action.payload,
      });
    }

    case ADD_FILE_PMDA: {
      return Object.assign({}, state, {
        listPMDA: [action.payload].concat(state.listPMDA),
       });
    }
    case EDIT_FILE_PMDA: {
      return Object.assign({}, state, {
        listPMDA: [
          action.payload,
          ...state.listPMDA.filter(element => element.id !== action.payload.id),
        ],
       });
    }
    case DELETE_FILE_PMDA: {
      return Object.assign({}, state, {
        listPMDA: [
          ...state.listPMDA.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
