import axios from "axios";
import config from "./Config";
import { datadogRum } from "@datadog/browser-rum";

const catchNotAuthorisedCallback = () => {
  localStorage.removeItem("token");
  window.location.replace(process.env.REACT_APP_SITE_PATH + "/");
};

async function get(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      datadogRum.addAction("Api GET", {
        url: config.baseUrl + apiEndpoint,
        response: response.data,
        status: response.status,
      });
      return response;
    })
    .catch((err) => {
      datadogRum.addError("Api Error GET", {
        url: config.baseUrl + apiEndpoint,
        error: err?.response?.data,
        status: err?.response?.status,
      });
      if (err?.response?.status === 401) {
        catchNotAuthorisedCallback();
      } else {
        return err;
      }
    });
}

async function post(apiEndpoint, payload = null) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      datadogRum.addAction("Api POST", {
        url: config.baseUrl + apiEndpoint,
        response: response.data,
        status: response.status,
        payload
      });
      return response;
    })
    .catch((err) => {
      datadogRum.addError("Api Error POST", {
        url: config.baseUrl + apiEndpoint,
        error: err?.response?.data,
        status: err?.response?.status,
        payload
      });
      if (err?.response?.status === 401) {
        catchNotAuthorisedCallback();
      } else {
        return err;
      }
    });
}

async function remove(apiEndpoint, payload = null) {
  return axios
    .delete(config.baseUrl + apiEndpoint, {
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      datadogRum.addAction("Api DELETE", {
        url: config.baseUrl + apiEndpoint,
        response: response.data,
        status: response.status,
        payload
      });
      return response;
    })
    .catch((err) => {
      datadogRum.addError("Api Error DELETE", {
        url: config.baseUrl + apiEndpoint,
        error: err?.response?.data,
        status: err?.response?.status,
        payload
      });
      if (err?.response?.status === 401) {
        catchNotAuthorisedCallback();
      } else {
        return err;
      }
    });
}

async function put(apiEndpoint, payload = null) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      datadogRum.addAction("Api PUT", {
        url: config.baseUrl + apiEndpoint,
        response: response.data,
        status: response.status,
        payload
      });
      return response;
    })
    .catch((err) => {
      datadogRum.addError("Api Error PUT", {
        url: config.baseUrl + apiEndpoint,
        error: err?.response?.data,
        status: err?.response?.status,
        payload
      });
      if (err?.response?.status === 401) {
        catchNotAuthorisedCallback();
      } else {
        return err;
      }
    });
}

async function patch(apiEndpoint, payload = null) {
  return axios
    .patch(config.baseUrl + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      datadogRum.addAction("Api PATCH", {
        url: config.baseUrl + apiEndpoint,
        response: response.data,
        status: response.status,
        payload
      });
      return response;
    })
    .catch((err) => {
      datadogRum.addError("Api Error PATCH", {
        url: config.baseUrl + apiEndpoint,
        error: err?.response?.data,
        status: err?.response?.status,
        payload
      });
      if (err?.response?.status === 401) {
        catchNotAuthorisedCallback();
      } else {
        return err;
      }
    });
}

export const crudService = {
  get,
  post,
  remove,
  put,
  patch,
};
