/* eslint import/no-anonymous-default-export: "off" */
import {
  GET_FILE_BIOSIMILAIRE,
  ADD_FILE_BIOSIMILAIRE,
  EDIT_FILE_BIOSIMILAIRE,
  DELETE_FILE_BIOSIMILAIRE,
} from "../../Const/ActionTypes";
const initialState = {
  listBiosimilaire: [],
 };
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILE_BIOSIMILAIRE: {
      return Object.assign({}, state, {
        listBiosimilaire: action.payload,
      });
    }
    case ADD_FILE_BIOSIMILAIRE: {
      return Object.assign({}, state, {
        listBiosimilaire: [action.payload].concat(state.listBiosimilaire),
       });
    }
    case EDIT_FILE_BIOSIMILAIRE: {
      return Object.assign({}, state, {
        listBiosimilaire: [
          action.payload,
          ...state.listBiosimilaire.filter((element) => element.id !== action.payload.id),
        ],
       });
    }
    case DELETE_FILE_BIOSIMILAIRE: {
      return Object.assign({}, state, {
        listBiosimilaire: [...state.listBiosimilaire.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
