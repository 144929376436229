import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SideNav from "../CommunComponents/SideNav";

class UserAccountHeader extends Component {
  render() {
    const { filePath } = this.props;

    return (
      <>
        <SideNav />

        <div className="dashboard__actions actions-dashboard">
          <div className="actions-dashboard__left">
            <h1 className="dashboard__title title">Gestion des comptes</h1>
          </div>

          <div className="actions-dashboard__right">
            <Link to={`/admin/${filePath}/create`}>
              <button className="button button-empty _icon-plus" type="button">
                Ajouter un utilisateur
              </button>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps, {})(UserAccountHeader);
