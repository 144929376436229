import React, { useEffect, useState } from "react";
import SpinnerConnect from "./Loading/Spinner/SpinnerConnect";

export default function SSO() {
  const [displaySpinner] = useState(true);

  useEffect(() => {
    window.location.replace(process.env.REACT_APP_LOGIN_PATH);
  }, []);
  return <div>{displaySpinner && <SpinnerConnect />}</div>;
}
