import {
  GET_ACTIVE_INGREDIENT,
  ADD_ACTIVE_INGREDIENT,
  EDIT_ACTIVE_INGREDIENT,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE_LIST,
  DELETE_ACTIVE_INGREDIENT,
} from "../../Const/ActionTypes";

import _ from "lodash";
import { setLoading, unsetLoading } from "./UIActions";
import { decrementCount, incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getActiveIngredient = (data) => {
  let filter = JSON.stringify({
    fields: {
      id: true,
      nameFr: true,
      nameEn: true,
    },
    order: ["id DESC"],
  });

  return async (dispatch) => {
    await crudService
      .get(`/chemical-ingredients?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_ACTIVE_INGREDIENT,
          payload: res.data.map((element) => {
            return {
              ...element,
              value: element.id,
              label: element.nameEn,
            };
          }),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const addActiveIngredient = (data) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/chemical-ingredients`, data)
      .then((res) => {
        if (res.data.success.length > 0) {
          dispatch({
            type: ADD_ACTIVE_INGREDIENT,
            payload: res.data.success?.map((element) => ({
              ...element,
              value: element.id,
              label: element.nameEn,
            })),
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "The creation of " +
              res.data.success.length +
              " elements is successful",
          });

          dispatch(incrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
        if (res.data.failed.length > 0) {
          dispatch({
            type: SHOW_ERROR_MESSAGE_LIST,
            payload: _.uniq(res.data.failed),
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const editActiveIngredient = (data) => {
  return (dispatch) => {
    crudService
      .patch(`/chemical-ingredients/` + data.id, data)
      .then((res) => {
        dispatch({
          type: EDIT_ACTIVE_INGREDIENT,
          payload: { ...res.data, value: res.data?.id, label: res.data?.nameEn },
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La modification  est effectuée avec succès",
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      });
  };
};

export const deleteActiveIngredient = (id) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .remove(`/chemical-ingredients/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_ACTIVE_INGREDIENT,
          payload: id,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });

        dispatch(decrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        window.scrollTo(0, 0);
        dispatch(unsetLoading());
      });
  };
};
