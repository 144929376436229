/* eslint import/no-anonymous-default-export: "off" */

import {
  SET_ALL_FILE_HEADERS,
  SET_FILE_HEADERS,
  SET_FILE_MODAL_HEADERS,
  SET_FILE_STATUS,
  SEELECT_FILE_MODAL_HEADERS,
  SEELECT_ALL_FILE_MODAL_HEADERS,
  SET_DUPLICATION_ID,
  INCREMENT_COUNT,
  DECREMENT_COUNT,
  SET_MODAL_FILE_STATUS,
  CLEAR_MODAL_FILE_STATUS,
  GET_SEARCHED_FILES_BY_COLUMN,
  SET_SEARCH_FILE_STATUS,
  CLEAR_SEARCH_FILE_STATUS,
  COUNT_SEARCH,
  STORE_SELECTED_ITEM,
  STORE_STATE,
  SET_SORTED_FILE_LIST,
  SET_SEARCH_FILTER_ARGS,
  UPDATE_SEARCHED_FILES,
} from "../../Const/ActionTypes";

const initialState = {
  currentFile: null,
  status: "get",
  sections: [],
  headers: [],
  headersKey: null,
  fileSlected: {},
  deleteAction: null,
  fileApi: null,
  page: 0,
  rowsPerPage: 5,
  submitAction: null,
  getAction: null,
  fileModalHeaders: [],
  handleOpenSection: null,
  titlesList: [],
  titlesDeList: [],
  filePath: "",
  duplicationId: null,
  permissions: undefined,
  maxRows: 0,
  maxRowReceived: 0,
  maxRowSended: 0,
  searchedFiles: [],
  hTAdecisionList: null,
  reimbursementList: null,
  fromSpecificFile: false,
  attribut: "",
  order: "",
  columnSelected: "",
  searchFilterArgs: null,

  // Modal attributs section
  modalAttributes: {
    pageModal: 0,
    rowsPerPageModal: 5,
    fileApiModal: "",
    agencyLabelModal: "",
    headersModal: null,
    getActionTypeModal: null,
    maxRowsModal: 0,
    searchOptionsListModal: [],
    fromSpecificFile: false,
    firstOpen: false,
    fromPagination: false,
  },

  // search attributs section
  searchAttributes: {
    pageSearch: 0,
    rowsPerPageSearch: 5,
    maxRowsSearch: 0,
    fromPagination: false,
    whereSpecifiFile: "",
  },

  selectedItems: [],
  toEditItem: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FILE_STATUS: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }

    case SET_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: action.payload,
      });
    }

    case SET_FILE_HEADERS: {
      return Object.assign({}, state, {
        headers: state.headers.map((element) =>
          element.label === action.payload.label
            ? { ...element, checked: !element.checked }
            : element
        ),
      });
    }

    case SET_ALL_FILE_HEADERS: {
      return Object.assign({}, state, {
        headers: action.payload,
      });
    }

    case SEELECT_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: state.fileModalHeaders.map((element) =>
          element.label === action.payload.label
            ? { ...element, checked: !element.checked }
            : element
        ),
      });
    }

    case SEELECT_ALL_FILE_MODAL_HEADERS: {
      return Object.assign({}, state, {
        fileModalHeaders: action.payload,
      });
    }

    case SET_DUPLICATION_ID: {
      return Object.assign({}, state, {
        duplicationId: action.payload,
        fileSlected: {
          ...(state.fileSlected ?? {}),
          id: action.payload ?? state.fileSlected?.id,
        },
      });
    }

    case INCREMENT_COUNT: {
      return Object.assign({}, state, {
        maxRows: (state.maxRows += 1),
      });
    }

    case DECREMENT_COUNT: {
      return Object.assign({}, state, {
        maxRows: (state.maxRows -= 1),
      });
    }

    case SET_MODAL_FILE_STATUS: {
      return Object.assign({}, state, {
        modalAttributes: {
          ...state.modalAttributes,
          ...action.payload,
        },
      });
    }

    case CLEAR_MODAL_FILE_STATUS: {
      return Object.assign({}, state, {
        modalAttributes: {
          ...state.modalAttributes,
          agencyLabelModal: "",
          headersModal: null,
          getActionTypeModal: null,
          searchOptionsListModal: [],
          fromSpecificFile: false,
          fromPagination: false,
          firstOpen: false,
        },
      });
    }

    case SET_SEARCH_FILE_STATUS: {
      return Object.assign({}, state, {
        searchAttributes: {
          ...state.searchAttributes,
          ...action.payload,
        },
      });
    }

    case GET_SEARCHED_FILES_BY_COLUMN: {
      return Object.assign({}, state, {
        searchedFiles: action.payload,
      });
    }

    case UPDATE_SEARCHED_FILES: {
      return Object.assign({}, state, {
        searchedFiles: state.searchedFiles.map((element) =>
          element?.id === action.payload?.id ? action.payload : element
        ),
      });
    }

    case CLEAR_SEARCH_FILE_STATUS: {
      return Object.assign({}, state, {
        searchedFiles: [],
        searchAttributes: {
          pageSearch: 0,
          rowsPerPageSearch: 5,
          maxRowsSearch: 0,
          fromPagination: false,
          whereSpecifiFile: "",
        //  fromSpecificFile: false,
        },
      });
    }

    case COUNT_SEARCH: {
      return Object.assign({}, state, {
        searchAttributes: {
          ...state.searchAttributes,
          maxRowsSearch: action.payload,
        },
      });
    }

    case STORE_SELECTED_ITEM: {
      const { payload } = action;

      const foundItem = state.selectedItems.find(
        (item) => item.id === payload?.id
      );

      if (foundItem) {
        return {
          ...state,
          selectedItems: state.selectedItems.filter(
            (item) => item.id !== foundItem.id
          ),
        };
      }

      if (!payload) {
        return {
          ...state,
          selectedItems: [],
        };
      }

      return {
        ...state,
        selectedItems: [...state.selectedItems, payload],
      };
    }

    case STORE_STATE: {
      const { payload } = action;

      return {
        ...state,
        toEditItem: payload,
      };
    }

    case SET_SEARCH_FILTER_ARGS: {
      const { payload } = action;

      return {
        ...state,

        searchFilterArgs: payload,
      };
    }

    case SET_SORTED_FILE_LIST: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }

    default:
      return state;
  }
}
