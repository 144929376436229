import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../Redux/Actions/AuthActions";
import SpinnerConnect from "./Loading/Spinner/SpinnerConnect";

export default function Callback() {
  const dispatch = useDispatch();
  let history = useHistory();

  const authToken = useSelector((state) => state.AuthReducer.authToken);
  const userProfile = useSelector((state) => state.AuthReducer.userProfile);
  useEffect(() => {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      let token = hash.toString();
      let decoded = token.match("access_token=(.*)");
      let tokenValue = decoded[1];
      window.localStorage.setItem("token", tokenValue);
      if (tokenValue && !userProfile?.id) {
        dispatch(getUserInfo("Callback Callback Callback "));
      }
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    if (authToken && userProfile?.id) {
      history.push("/admin");
    }
  }, [authToken, userProfile, history]);
  return (
    <div>
      <SpinnerConnect />
    </div>
  );
}
