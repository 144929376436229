import { crudService } from "../../Config/CrudService";
import {
  GET_LIST_ASMR,
  GET_LIST_SMR,
  GET_SEARCHED_FILES_BY_COLUMN,
} from "../../Const/ActionTypes";

import { setLoading, unsetLoading } from "./UIActions";

export const getSMRList = (
  limit = 5,
  offset = 0,
  where = null,
  search = false
) => {
  let initFilter = {
    order: ["smr_id DESC"],
    fields: [
      "smr_id",
      "smr_type",
      "smr_title",
      "smr_trs_code_prioritis",
      "smr_trs_id",
    ],
    limit,
    offset,
  };

  if (where) {
    initFilter = {
      ...initFilter,
      where,
    };
  }

  const filter = JSON.stringify(initFilter);

  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .get(`/service-medical-rendus?filter=${filter}`)
      .then((res) => {
        if (search) {
          dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });

          return;
        }

        dispatch({
          type: GET_LIST_SMR,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getASMRList = (
  limit = 5,
  offset = 0,
  where = null,
  search = false
) => {
  let initFilter = {
    order: ["asmr_id DESC"],
    fields: ["asmr_id", "asmr_title", "asmr_trs_code_prioritis"],
    limit,
    offset,
  };

  if (where) {
    initFilter = {
      ...initFilter,
      where,
    };
  }

  const filter = JSON.stringify(initFilter);

  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .get(`/amelioration-service-medical-rendus?filter=${filter}`)
      .then((res) => {
        if (search) {
          dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });

          return;
        }

        dispatch({
          type: GET_LIST_ASMR,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};
