import React from "react";
import Styles from "./Error.module.css";
import WarningIcon from "../../images/warning-icon.svg";
import { Link } from "react-router-dom";

function ErrorConnecting() {
  return (
    <main className={`main ${Styles["error-connecting__main"]}`}>
      <section className="dashboard">
        <div className="dashboard__container _container">
          <div className="dashboard__wrapper">
            <div className={Styles["error-boundray__container"]}>
              <div className={Styles["error-boundray__container-content"]}>
                <img src={WarningIcon} width="20%" alt="warning icon" />
              </div>

              <div className={Styles["error-boundray__container-content"]}>
                <h1>
                  Error when{" "}
                  <span style={{ color: "#ff763f" }}>connecting.</span>
                </h1>
              </div>

              <div className={Styles["error-boundray__container-content"]}>
                <Link to="/" className={Styles["error-boundray__home-btn"]}>
                  <i className="fas fa-home"></i> Take me home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ErrorConnecting;
