import {
  ADD_JO,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SELECT_JO,
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
} from "../../Const/ActionTypes";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const globalJOSubmit = (data, duplication = false) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .post(`/boite-presentation-upload-excels?type=${data.type}`, formData)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: ADD_JO,
            payload: res.data,
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "Une nouvelle fiche JO est ajoutée avec succès ( ID : " +
              res.data.id +
              " )",
          });
          dispatch(incrementCount());
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getJoList = () => {
  return (dispatch) => {
    dispatch(unsetLoading());
  };
};

export const getJoById = (id, redirect = null) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .get(`/boite-presentation-upload-excels/${id}`)
      .then((res) => {
        const foundFile = res.data;
        if (foundFile) {
          dispatch({
            type: SELECT_JO,
            payload: res.data,
          });
        } else {
          if (redirect) {
            redirect();
          }
        }
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};
