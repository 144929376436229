/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_ACE_FILE_LIST,
  ADD_FILE_ACE,
  EDIT_FILE_ACE,
  DELETE_FILE_ACE,
} from '../../Const/ActionTypes';

const initialState = {
  listAce: [],
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACE_FILE_LIST: {
      return Object.assign({}, state, {
        listAce: action.payload,
      });
    }

    case ADD_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [action.payload].concat(state.listAce),
       });
    }
    case EDIT_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [
          action.payload,
          ...state.listAce.filter(element => element.id !== action.payload.id),
        ],
         
      });
    }
    case DELETE_FILE_ACE: {
      return Object.assign({}, state, {
        listAce: [
          ...state.listAce.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
