import * as ActionTypes from "./ActionTypes";
import {
  defaultSearchOptionsList,
  filterDefaultSearchOptionsList,
  boitePresentationSearchOptionsList,
  ansmSearchOptionsList,
  biosimilairesSearchOptionsList,
  tfrSearchOptionsList,
  nctsInfosSearchOptionsList,
  indicationJoSearchOptionsList,
  categorySearchOptionsList,
  chemicalIngredientsSearchOptionsList,
  ecoEvalSearchOptionsList,
  diseasesSearchOptionsList,
} from "./SearchConsts";
import {
  ansmHeaderItems,
  BIOSIMILAIRESHeaderItems,
  boitePresentationHeaderItems,
  communHeaderItems,
  HeaderItemsPricnigAustralie,
  HeaderItemsPricnigBresil,
  HeaderItemsPricnigGermany,
  HeaderItemsPricnigINAMI,
  HeaderItemsPricnigItaly,
  HeaderItemsPricnigJapan,
  HeaderItemsPricnigKorea,
  HeaderItemsPricnigSpain,
  HeaderItemsPricnigSweden,
  ICERHeaderItems,
  regulatoryHeaderItems,
  TFRHeaderItems,
} from "./selectListValues";

export const treatmentLineItem = {
  furtherLinesIncluded: 0,
  stage: "",
  relapsing: "",
  symptomaticVisceralInvolvement: "",
  node: "",
  pik3ca: "",
  hr: "",
  her2: "",
  specificity: "",
  perimenopausal: 0,
  postmenopausal: 0,
  premenopausal: 0,
  ecog4: 0,
  ecog3: 0,
  ecog2: 0,
  ecog1: 0,
  ecog0: 0,
  transplantEligibility: "",
  pretermNewBornInfants: 0,
  termNewBornInfants: 0,
  infantsAndToddlers: 0,
  children: 0,
  adolescents: 0,
  adult: 0,
  ageFrom: null,
  ageTo: null,
  ecogFrom: null,
  ecogTo: null,
  //* added proprieties */
  expired: 0,
  gender: "",
  tp53: "",
  deletion17P: 0,
  msiHDmmr: 0,
};

export const indicationJoInformationItem = {
  created_at: null,
  indicationRemboursable: "",
  //--------------------
  infoJoSelectedSS: "",
  infoJoSelectedSSen: "",
  infoJoDateSS: null,
  applicationDateSS: null,
  infoJoLinkSS: "",
  //-----------------------
  infoJoSelectedCOLL: "",
  infoJoSelectedCOLLen: "",
  infoJoDateCOLL: null,
  applicationDateCOLL: null,
  infoJoLinkCOLL: "",
  //-------------------------------
  infoJoSelectedT2A: "",
  infoJoSelectedT2Aen: "",
  infoJoDateT2A: null,
  applicationDateT2A: null,
  infoJoLinkT2A: "",
  //-------------------------------
  infoJoSelectedRETRO: "",
  infoJoSelectedRETROen: "",
  infoJoDateRETRO: null,
  applicationDateRETRO: null,
  infoJoLinkRETRO: "",
};

export const agencyInformation = {
  ///------EUROPE
  Ema: {
    agencyLabelModal: "EMA (FR)",
    listLabel: "listEma",
    getAction: "getEMAFiles",
    fileApiModal: "emas",
    getActionTypeModal: ActionTypes.GET_EMA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement" && label !== "HTA decision"
    ),
    headerItems: [
      ...regulatoryHeaderItems.map(({ label, ...rest }) =>
        label.includes("Indexation") || label === "Créé" || label === "Modifiés"
          ? { ...rest, label, checked: false }
          : { label, ...rest }
      ),
      {
        label: "Indication",
        up: false,
        down: false,
        sorted: false,
        checked: true,
      },
    ],
  },
  Nice: {
    agencyLabelModal: "NICE (UK)",
    listLabel: "listNice",
    getAction: "getNiceFiles",
    fileApiModal: "nices",
    getActionTypeModal: ActionTypes.GET_NICE_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Smc: {
    agencyLabelModal: "SMC (UK)",
    listLabel: "listSMC",
    getAction: "getSmcFiles",
    fileApiModal: "smcs",
    getActionTypeModal: ActionTypes.GET_SMC_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Cdf: {
    agencyLabelModal: "CDF (UK)",
    listLabel: "listCDF",
    getAction: "getCDFFiles",
    fileApiModal: "cdfs",
    getActionTypeModal: ActionTypes.GET_CDF_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Tlv: {
    agencyLabelModal: "TLV (SE)",
    listLabel: "listTlv",
    getAction: "getTlvFiles",
    fileApiModal: "tlvs",
    getActionTypeModal: ActionTypes.GET_TLV_FILE_LIST,
    searchOptionsListModal: filterDefaultSearchOptionsList("Firm"),
  },
  Ntcouncil: {
    agencyLabelModal: "NT Council",
    listLabel: "listNtCouncil",
    getAction: "getNtcouncilFiles",
    fileApiModal: "ntcouncils",
    getActionTypeModal: ActionTypes.GET_NTCOUNCIL_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Zinl: {
    agencyLabelModal: "Zinl (NL)",
    listLabel: "listZinl",
    getAction: "getZinlFiles",
    fileApiModal: "zinls",
    getActionTypeModal: ActionTypes.GET_ZINL_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Spain: {
    agencyLabelModal: "Spain",
    listLabel: "listSpain",
    getAction: "getSpainFiles",
    fileApiModal: "spains",
    getActionTypeModal: ActionTypes.GET_SPAIN_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Italy: {
    agencyLabelModal: "ITALY",
    listLabel: "listItaly",
    getAction: "getItalyFiles",
    fileApiModal: "italys",
    getActionTypeModal: ActionTypes.GET_ITALY_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Ncpe: {
    agencyLabelModal: "NCPE (IE)",
    listLabel: "listNcpe",
    getAction: "getNcpeFiles",
    fileApiModal: "ncpes",
    getActionTypeModal: ActionTypes.GET_NCPE_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Awmsg: {
    agencyLabelModal: "AWMSG",
    listLabel: "listAWSMG",
    getAction: "getAwmsgFiles",
    fileApiModal: "awmsgs",
    getActionTypeModal: ActionTypes.GET_AWMSG_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Inami: {
    agencyLabelModal: "INAMI (BE)",
    listLabel: "listInami",
    getAction: "getInamiFiles",
    fileApiModal: "inamis",
    getActionTypeModal: ActionTypes.GET_INAMI_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Aotmit: {
    agencyLabelModal: "AOTMIT – POLAND",
    listLabel: "listAOTMIT",
    getAction: "getAtomitFiles",
    fileApiModal: "aotmits",
    getActionTypeModal: ActionTypes.GET_AOTMIT_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Iqwig: {
    agencyLabelModal: "IQWIG (DE)",
    listLabel: "listIqwig",
    getAction: "getIQWIGFiles",
    fileApiModal: "iqwigs",
    getActionTypeModal: ActionTypes.GET_IQWIG_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Gba: {
    agencyLabelModal: "GBA (DE)",
    listLabel: "listGba",
    getAction: "getGBAFiles",
    fileApiModal: "gbas",
    getActionTypeModal: ActionTypes.GET_GBA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  //---- FRANCE
  Ceesp: {
    agencyLabelModal: "CEESP (FR)",
    listLabel: "listCEESP",
    getAction: "getCEESPFiles",
    fileApiModal: "ceesps",
    getActionTypeModal: ActionTypes.GET_CEESP_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement"
    ),
  },
  Rtu: {
    agencyLabelModal: "RTU (FR)",
    listLabel: "listRTU",
    getAction: "getRTUFiles",
    fileApiModal: "rtuses",
    getActionTypeModal: ActionTypes.GET_RTU_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  //---- APAC
  Pbac: {
    agencyLabelModal: "PBAC (AU)",
    listLabel: "listPbac",
    getAction: "getPbacFiles",
    fileApiModal: "pbacs",
    getActionTypeModal: ActionTypes.GET_PBAC_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Singapore: {
    agencyLabelModal: "ACE (SG)",
    listLabel: "listAce",
    getAction: "getACEFiles",
    fileApiModal: "singapores",
    getActionTypeModal: ActionTypes.GET_ACE_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Hira: {
    agencyLabelModal: "HIRA",
    listLabel: "listHira",
    getAction: "getHIRAFiles",
    fileApiModal: "hiras",
    getActionTypeModal: ActionTypes.GET_HIRA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Nihta: {
    agencyLabelModal: "NIHTA",
    listLabel: "listNihta",
    getAction: "getNIHTAFiles",
    fileApiModal: "nihtas",
    getActionTypeModal: ActionTypes.GET_NIHTA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Pmda: {
    agencyLabelModal: "PMDA",
    listLabel: "listPMDA",
    getAction: "getPmdaFiles",
    fileApiModal: "pmdas",
    getActionTypeModal: ActionTypes.GET_PMDA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  //---- AMERICA
  Fda: {
    agencyLabelModal: "FDA (USA)",
    listLabel: "listFda",
    getAction: "getFdaFiles",
    fileApiModal: "fdas",
    getActionTypeModal: ActionTypes.GET_FDA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement" && label !== "HTA decision"
    ),
  },
  Bresil: {
    agencyLabelModal: "CONITEC (BR)",
    listLabel: "listBresil",
    getAction: "getBRESILFiles",
    fileApiModal: "bresils",
    getActionTypeModal: ActionTypes.GET_BRESIL_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Usa: {
    agencyLabelModal: "US (ICER)",
    listLabel: "listICER",
    getAction: "getICERFiles",
    fileApiModal: "usa-details",
    getActionTypeModal: ActionTypes.GET_ICER_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
    headerItems: ICERHeaderItems,
  },

  Ansm: {
    agencyLabelModal: "ANSM",
    listLabel: "listAnsm",
    getAction: "getANSMFiles",
    fileApiModal: "ansms",
    getActionTypeModal: ActionTypes.GET_ANSM_FILE_LIST,
    searchOptionsListModal: ansmSearchOptionsList,
  },

  Tfr: {
    agencyLabelModal: "Tarifs forfaitaires de responsabilité (TFR)",
    listLabel: "listTFR",
    getAction: "getTRFFiles",
    fileApiModal: "tfrs",
    getActionTypeModal: ActionTypes.GET_TRF_FILE_LIST,
    searchOptionsListModal: tfrSearchOptionsList,
  },

  BoitePresentation: {
    agencyLabelModal: "Boîte présentation",
    listLabel: "listBoitePresentation",
    getAction: "getBoitePresentationFiles",
    fileApiModal: "boite-presentations",
    getActionTypeModal: ActionTypes.GET_BOITE_PRESENTATION_FILE_LIST,
    searchOptionsListModal: boitePresentationSearchOptionsList,
    headerItems: boitePresentationHeaderItems,
    fromSpecificFile: true,
  },

  Biosimilaire: {
    agencyLabelModal: "Biosimilaires",
    listLabel: "listBiosimilaire",
    getAction: "getBiosimilaireFiles",
    fileApiModal: "biosimilaires",
    getActionTypeModal: ActionTypes.GET_FILE_BIOSIMILAIRE,
    searchOptionsListModal: biosimilairesSearchOptionsList,
  },

  AtuFrance: {
    agencyLabelModal: "AP/AC (FR)",
    listLabel: "listAPAC",
    getAction: "getAPACFiles",
    fileApiModal: "atu-frances",
    getActionTypeModal: ActionTypes.GET_APAC_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Transparence: {
    agencyLabelModal: "Transparency (FR)",
    listLabel: "listTransparency",
    getAction: "getTransparencyFiles",
    fileApiModal: "transparences",
    getActionTypeModal: ActionTypes.GET_TRANSPARENCY_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
    headerItems: [
      ...communHeaderItems,
      {
        label: "Indication",
        up: false,
        down: false,
        sorted: false,
        checked: true,
      },
    ],
  },

  Nhsa: {
    agencyLabelModal: "NHSA",
    listLabel: "listNhsa",
    getAction: "getNhsaFiles",
    fileApiModal: "nhsas",
    getActionTypeModal: ActionTypes.GET_NHSA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Nmpa: {
    agencyLabelModal: "NMPA (CN)",
    listLabel: "listNmpa",
    getAction: "getNmpaFiles",
    fileApiModal: "nmpas",
    getActionTypeModal: ActionTypes.GET_NMPA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Perc: {
    agencyLabelModal: "pCODR (CA)",
    listLabel: "listpCODR",
    getAction: "getpCODRFiles",
    fileApiModal: "percs",
    getActionTypeModal: ActionTypes.GET_pCODR_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Cadth: {
    agencyLabelModal: "CADTH(CA)",
    listLabel: "listCadth",
    getAction: "getCADTHFiles",
    fileApiModal: "cadths",
    getActionTypeModal: ActionTypes.GET_CADTH_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Peru: {
    agencyLabelModal: "PERU",
    listLabel: "listPeru",
    getAction: "getPERUFiles",
    fileApiModal: "perus",
    getActionTypeModal: ActionTypes.GET_PERU_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList,
  },

  //Eco & clinical templates

  EconomicEvaluationLinks: {
    agencyLabelModal: "Economic Evaluation Links",
    listLabel: "listEconomicEvaluation",
    getAction: "getEconomicEvaluationFiles",
    fileApiModal: "economic-evaluations",
    getActionTypeModal: ActionTypes.GET_ECONOMIC_EVALUATION_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement" && label !== "HTA decision"
    ),
  },
};

export const pricingAgencyInformation = {
  PrixGermany: {
    agencyLabelModal: "Prix Germany",
    listLabel: "listPricingGermany",
    getAction: "getPricingGermanyFiles",
    fileApiModal: "prix-germanies",
    getActionTypeModal: ActionTypes.GET_PRICING_GERMANY_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigGermany,
  },

  PrixSpain: {
    agencyLabelModal: "Prix Spain",
    listLabel: "listPricingSpain",
    getAction: "getPricingSpainFiles",
    fileApiModal: "prix-spains",
    getActionTypeModal: ActionTypes.GET_PRICING_SPAIN_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigSpain,
  },

  PrixItaly: {
    agencyLabelModal: "Prix Italy",
    listLabel: "listPricingItaly",
    getAction: "getPricingItalyFiles",
    fileApiModal: "prix-italies",
    getActionTypeModal: ActionTypes.GET_PRICING_ITALY_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigItaly,
  },

  PrixSweden: {
    agencyLabelModal: "Prix Sweden",
    listLabel: "listPricingSweden",
    getAction: "getPricingSwedenFiles",
    fileApiModal: "prix-swedens",
    getActionTypeModal: ActionTypes.GET_PRICING_SWEDEN_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigSweden,
  },

  PrixAustralie: {
    agencyLabelModal: "Prix Australie",
    listLabel: "listPricingAustralie",
    getAction: "getPricingAustralieFiles",
    fileApiModal: "pbac-prix-australies",
    getActionTypeModal: ActionTypes.GET_PRICING_AUSTRALIE_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigAustralie,
  },

  PrixBresil: {
    agencyLabelModal: "Prix Bresil",
    listLabel: "listPricingBresil",
    getAction: "getPricingBresilFiles",
    fileApiModal: "prix-bresils",
    getActionTypeModal: ActionTypes.GET_PRICING_BRESIL_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigBresil,
  },

  PrixInami: {
    agencyLabelModal: "Prix INAMI",
    listLabel: "listPricingINAMI",
    getAction: "getPricingINAMIFiles",
    fileApiModal: "prix-inamis",
    getActionTypeModal: ActionTypes.GET_PRICING_INAMI_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigINAMI,
  },

  PrixJapan: {
    agencyLabelModal: "Prix Japan",
    listLabel: "listPricingJapan",
    getAction: "getPricingJapanFiles",
    fileApiModal: "prix-japans",
    getActionTypeModal: ActionTypes.GET_PRICING_JAPAN_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigJapan,
  },

  PrixKorea: {
    agencyLabelModal: "Prix Korea",
    listLabel: "listPricingKorea",
    getAction: "getPricingKoreaFiles",
    fileApiModal: "prix-koreas",
    getActionTypeModal: ActionTypes.GET_PRICING_KOREA_FILE_LIST,
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label === "ID"
    ),
    headerItems: HeaderItemsPricnigKorea,
  },
};

export const specificagencyInformation = {
  ansm: {
    agencyLabel: "ANSM",
    listLabel: "listAnsm",
    headerLabel: "ansmHeader",
    getActionTypeModal: ActionTypes.GET_ANSM_FILE_LIST,
    fileApiModal: "ansms",
    headers: ansmHeaderItems,
    searchOptionsListModal: ansmSearchOptionsList,
  },

  biosimilaires: {
    agencyLabel: "Biosimilaires",
    listLabel: "listBiosimilaire",
    headerLabel: "biosimilairesHeader",
    getActionTypeModal: ActionTypes.GET_FILE_BIOSIMILAIRE,
    fileApiModal: "biosimilaires",
    headers: BIOSIMILAIRESHeaderItems,
    searchOptionsListModal: biosimilairesSearchOptionsList,
  },

  tfr: {
    agencyLabel: "Tarifs forfaitaires de responsabilité (TFR)",
    listLabel: "listTFR",
    headerLabel: "tfrHeader",
    fileApiModal: "tfrs",
    getActionTypeModal: ActionTypes.GET_TRF_FILE_LIST,
    headers: TFRHeaderItems,
    searchOptionsListModal: tfrSearchOptionsList,
  },
};

export const searchAgencyInformation = {
  ///------EUROPE
  Ema: {
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement" && label !== "HTA decision"
    ),
  },
  Nice: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Smc: {
    agencyLabelModal: "SMC (UK)",

    searchOptionsListModal: defaultSearchOptionsList,
  },
  Cdf: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Tlv: {
    searchOptionsListModal: filterDefaultSearchOptionsList("Firm"),
  },
  Ntcouncil: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Zinl: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Spain: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Italy: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Ncpe: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Awmsg: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Inami: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Aotmit: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Iqwig: {
    searchOptionsListModal: [
      ...defaultSearchOptionsList,
      {
        label: "Project number",
        value: "guidance_id",
      },
    ],
  },
  Gba: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  //---- FRANCE
  Ceesp: {
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement"
    ),
  },
  Rtu: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  //---- APAC
  Pbac: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Singapore: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Hira: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Nihta: {
    searchOptionsListModal: defaultSearchOptionsList,
  },
  Pmda: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  //---- AMERICA
  Fda: {
    searchOptionsListModal: defaultSearchOptionsList.filter(
      ({ label }) => label !== "Reimbursement" && label !== "HTA decision"
    ),
  },
  Usa: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Ansm: {
    searchOptionsListModal: ansmSearchOptionsList,
  },

  Tfr: {
    searchOptionsListModal: tfrSearchOptionsList,
  },

  BoitePresentation: {
    searchOptionsListModal: boitePresentationSearchOptionsList,
  },

  Biosimilaire: {
    searchOptionsListModal: biosimilairesSearchOptionsList,
  },

  AtuFrance: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Transparence: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Nhsa: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Nmpa: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Perc: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Cadth: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  Peru: {
    searchOptionsListModal: defaultSearchOptionsList,
  },

  "ncts-infos": {
    searchOptionsListModal: nctsInfosSearchOptionsList,
  },

  "indication-jos": { searchOptionsListModal: indicationJoSearchOptionsList },

  "drug-categories": { searchOptionsListModal: categorySearchOptionsList },

  "chemical-ingredients": {
    searchOptionsListModal: chemicalIngredientsSearchOptionsList,
  },

  diseases: {
    searchOptionsListModal: diseasesSearchOptionsList,
  },

  "economic-evaluations": {
    searchOptionsListModal: ecoEvalSearchOptionsList,
  },

  // pricing
  prix: {
    searchOptionsListModal: [
      {
        label: "ID",
        value: "id",
      },
      {
        label: "Drug name",
        value: "drug_name",
      },
    ],
  },
};

export const joTypes = {
  pv: "Prix évolution Ville",
  pt: "Prix évolution T2A",
  pr: "Prix évolution Rétrocession",
};
