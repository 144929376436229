import {
  ADD_TRANSPARENCY_FILE_LIST,
  DELETE_TRANSPARENCY_FILE_LIST,
  EDIT_TRANSPARENCY_FILE_LIST,
  GET_TRANSPARENCY_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listTransparency: [],
};

export default function transparency(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: action.payload,
      });
    }
    case EDIT_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: state.listTransparency.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
      });
    }
    case ADD_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: [action.payload].concat(state.listTransparency),
      });
    }

    case DELETE_TRANSPARENCY_FILE_LIST: {
      return Object.assign({}, state, {
        listTransparency: [
          ...state.listTransparency.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
