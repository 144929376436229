import React, { Component } from "react";
import { connect } from "react-redux";
import { setFileStatus } from "../../Redux/Actions/FileSettingActions";
import TableCellValueUsers from "./TableCellValueUsers";
import { usersTableHead } from "../../Const/selectListValues";
import { Link } from "react-router-dom";

class UsersFileListItem extends Component {
  render() {
    const { row, handleChangeEdit, handleOpenDelete, filePath } = this.props;

    return (
      <tr>
        {usersTableHead.map((column, index) => (
          <TableCellValueUsers key={index} columnName={column} fileItem={row} />
        ))}
        {this.props.activeAction !== false && (
          <td>
            <div className="dashboard__item-wrapper">
              <Link
                className="dashboard__item dashboard__item_button dashboard__item_edit _icon-edit"
                to={{
                  pathname: `/admin/${filePath}/modify/${row.id}`,
                  state: {
                    fromTask: true,
                    fileId: row.id,
                  },
                }}
                onClick={() => {
                  handleChangeEdit(row);
                }}
              />
              <span
                onClick={() => {
                  handleOpenDelete(row.id);
                }}
                className="dashboard__item dashboard__item_button dashboard__item_delete _icon-delete"
              />
            </div>
          </td>
        )}
      </tr>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps, {
  setFileStatus,
})(UsersFileListItem);
