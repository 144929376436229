import React, { Component } from "react";
import { setFileStatus } from "../../../../Redux/Actions/FileSettingActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableCellExecutedList from "./TableCellExecutedList";

class JoFileListItem extends Component {
  render() {
    const { row, joHeaders, handleEditEmailAlert, id } = this.props;
    return (
      <tr>
        {joHeaders
          .filter((e) => e.checked)
          .map((column, index) => (
            <TableCellExecutedList
              key={index}
              columnName={column}
              fileItem={row}
              getFile={this.props.getFile}
              activeAction={this.props.activeAction}
              handleEditEmailAlert={handleEditEmailAlert}
              id={id}
            />
          ))}
        {this.props.activeAction !== false && (
          <td>
            <div className="dashboard__item-wrapper">
              <Link
                target="_blank"
                to={{
                  pathname: `/admin/boite_presentations/modify/${row.boitePresentation.bp_id}`,
                  state: {
                    fromTask: true,
                    fileId: row.id,
                  },
                }}
                onClick={() =>
                  this.props.setFileStatus({ fileSlected: row, status: "edit" })
                }
                className="dashboard__item dashboard__item_button dashboard__item_edit
                _icon-edit"
              />
            </div>
          </td>
        )}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
  };
};

export default connect(mapStateToProps, {
  setFileStatus,
})(JoFileListItem);
