import React from "react";
import { useParams } from "react-router-dom";
import Styles from "./Spinner.module.css";

function Spinner({ isFile }) {
  const { fileId } = useParams();

  return (
    <div className={Styles["loader-wrapper"]}>
      <div className={Styles["loader-roller"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={Styles["loader-text"]}>
        <h1>{`Loading ${isFile ? `file with ID: ${fileId}` : "page..."}`}</h1>
      </div>
    </div>
  );
}

export default Spinner;
