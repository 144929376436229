/* eslint import/no-anonymous-default-export: "off" */

import { GET_LIST_ASMR, GET_LIST_SMR } from "../../Const/ActionTypes";

const initialState = {
  smrServerList: [],
  asmrServerList: [],
 };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SMR: {
      return Object.assign({}, state, {
        smrServerList: action.payload,
      });
    }
    case GET_LIST_ASMR: {
      return Object.assign({}, state, {
        asmrServerList: action.payload,
      });
    }

    default:
      return state;
  }
}
