/* eslint import/no-anonymous-default-export: "off" */
import {
  SUBMIT_USER,
  GET_USERS,
  GET_USER,
  DELETE_USER_BY_ID,
  EDIT_USER_BY_ID,
} from "../../Const/ActionTypes";

const initialState = {
  userList: [],
  user: {},
  userToEdit: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBMIT_USER: {
      return Object.assign({}, state, {
        userList: [action.payload, ...state.userList],
      });
    }
    case GET_USERS: {
      return Object.assign({}, state, {
        userList: action.payload,
      });
    }
    case GET_USER: {
      return Object.assign({}, state, {
        userToEdit: action.payload,
      });
    }
    case EDIT_USER_BY_ID: {
      return Object.assign({}, state, {
        userList: state.userList.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
      });
    }

    //   case GET_USER_PERMISSIONS: {
    //     return Object.assign({}, state, {
    //     permissionsToEdit: action.payload
    //   });
    // }

    case DELETE_USER_BY_ID: {
      return Object.assign({}, state, {
        userList: [
          ...state.userList.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default: {
      return state;
    }
  }
}
