/* eslint-disable */
import {
  GET_ANSM_FILE_LIST,
  ADD_FILE_ANSM,
  EDIT_FILE_ANSM,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_DUPLICATION_ID,
  GET_SEARCHED_FILES_BY_COLUMN,
  SET_FILE_STATUS,
} from "../../Const/ActionTypes";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getANSMFiles = () => {
  return (dispatch) => {
    crudService
      .get(`/ansms`)
      .then((res) => {
        dispatch({
          type: GET_ANSM_FILE_LIST,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalANSMSubmit = (data, duplication = false) => {
  let mutatedData = data;

  if (duplication) {
    const { ansmInformationsSection } = mutatedData;

    if (ansmInformationsSection?.ansmId) {
      mutatedData = {
        ...data,
        ansmInformationsSection: {
          ...ansmInformationsSection,
          ansmId: null,
        },
      };
    }
  }
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/ansms`, mutatedData)
      .then((res) => {
        dispatch({
          type: ADD_FILE_ANSM,
          payload: res.data,
        });

        dispatch(
          getAnsmPDF("ANSM", data, res.data.id, "ansms", EDIT_FILE_ANSM, [
            {
              name: "ansmInformationsSection",
              listOld: "oldAnsmInformationsSection",
              data: [
                {
                  path: "ansmModificationPdf",
                  link: "ansmModificationLink",
                  file: "ansmModificationFile",
                },
                {
                  path: "creationJoPdf",
                  link: "creationJoLink",
                  file: "creationJoFile",
                },
                {
                  path: "endJoPdf",
                  link: "endJoLink",
                  file: "endJoFile",
                },
                {
                  path: "ansmDeletePdf",
                  link: "ansmDeleteLink",
                  file: "ansmDeleteFile",
                },

                {
                  path: "ansmCreationPdf",
                  link: "ansmCreationLink",
                  file: "ansmCreationFile",
                },
              ],
            },
          ])
        );

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche ANSM est dupliquée avec succès" +
              " ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche ANSM est ajoutée avec succès" +
              " ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((error) => {
        console.error("error", error);
        let errorMsg =
          "Merci  de réessayer ultérieurement , une erreur s'est produite lors d'ajout d'ne nouvelle fiche ANSM";
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalANSMEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .put(`/ansms/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_ANSM,
          payload: res.data,
        });
        dispatch(
          getAnsmPDF("ANSM", data, res.data.id, "ansms", EDIT_FILE_ANSM, [
            {
              name: "ansmInformationsSection",
              listOld: "oldAnsmInformationsSection",
              data: [
                {
                  path: "ansmModificationPdf",
                  link: "ansmModificationLink",
                  file: "ansmModificationFile",
                },
                {
                  path: "creationJoPdf",
                  link: "creationJoLink",
                  file: "creationJoFile",
                },
                {
                  path: "endJoPdf",
                  link: "endJoLink",
                  file: "endJoFile",
                },
                {
                  path: "ansmDeletePdf",
                  link: "ansmDeleteLink",
                  file: "ansmDeleteFile",
                },

                {
                  path: "ansmCreationPdf",
                  link: "ansmCreationLink",
                  file: "ansmCreationFile",
                },
              ],
            },
          ])
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload:
            sectionName === undefined
              ? "La fiche ANSM ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche ANSM"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const searchAnsm = (limit = 5, offset = 0, where = null) => {
  let querParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    querParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/ansms?${querParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const getAnsmPDF = (
  agencyModel,
  data,
  fileId,
  api,
  EDIT_TYPE,
  sectionName
) => {
  return (dispatch) => {
    if (!(data.id && data.id !== fileId)) {
      let enableUpload = false;

      /** if edit function  */
      if (data.id !== undefined) {
        sectionName.map((section) => {
          if (data[section.name] && data[section.listOld]) {
            let pdfToDelete;
            if (section.data) {
              pdfToDelete = section.data.map((dataEle) => {
                if (
                  data[section.name][dataEle.path] !==
                  data[section.listOld][dataEle.path]
                ) {
                  return data[section.listOld][dataEle.path];
                }
              });
            }
            pdfToDelete
              .filter((e) => e)
              .map((e) => {
                crudService.remove(`/blob-storage-azure`, { key: e });
                enableUpload = true;
              });
          }
        });
      }
      let formatedUrls = [];
      const formData = new FormData();
      sectionName.map((section, index) => {
        if (data[section.name]) {
          if (section.data) {
            section.data.map((dataEle) => {
              if (
                data[section.name][dataEle.link]?.length &&
                data[section.name][dataEle.file] === undefined &&
                (data[section.name][dataEle.path] === "" ||
                  data[section.name][dataEle.path] === null)
              ) {
                const fileExtension = data[section.name][dataEle.link].replace(
                  /^.*\./,
                  ""
                );
                formatedUrls.push({
                  name: index + dataEle.path + 1 + "_link." + fileExtension,
                  path: data[section.name][dataEle.link],
                  index: section.name + dataEle.path + 1,
                });
                enableUpload = true;
              }
              if (data[section.name][dataEle.file] !== undefined) {
                const myNewFile = new File(
                  [data[section.name][dataEle.file]],
                  dataEle.path + 1 + "_" + data[section.name][dataEle.file].name
                );
                formData.append(section.name + dataEle.path + 1, myNewFile);
                enableUpload = true;
              }
              return null;
            });
          }
        }
      });

      formData.append(
        "urlsList",
        JSON.stringify({
          data: formatedUrls,
        })
      );
      if (enableUpload) {
        crudService
          .post(`/blob-storage-azure/${agencyModel}/${fileId}`, formData)
          .then((s3Response) => {
            sectionName.map((section) => {
              if (data[section.name]) {
                if (section.data) {
                  const sectionObject = section.name;
                  let formatedObject = data[sectionObject];
                  section.data.map((dataEle) => {
                    let pathData = s3Response.data.find(
                      (e) => e.index === sectionObject + dataEle.path + 1
                    );

                    formatedObject = {
                      ...formatedObject,
                      [dataEle.path]:
                        pathData?.key ?? data[sectionObject][dataEle.path],
                      [dataEle.file]: undefined,
                    };
                  });

                  data[sectionObject] = formatedObject;
                }
              }
            });
            crudService
              .put(`/${api}/${fileId}`, {
                ...data,
              })
              .then((res) => {
                dispatch({
                  type: SET_FILE_STATUS,
                  payload: { fileSlected: res.data },
                });
                dispatch({
                  type: EDIT_TYPE,
                  payload: res.data,
                });
              })
              .catch((err) => {
                console.log("error message", err.message);
              });
          });
      }
    }
  };
};
