import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";

import ConfirmDelete from "./CommunComponents/ConfirmDelete";
import ConfirmDuplicate from "./CommunComponents/ConfirmDuplicate";
import NavBar from "./CommunComponents/NavBar";

import UserAccount from "./AccountMangement/UserAccount";

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { getDciList } from "../Redux/Actions/DciActions";
import { getActiveIngredient } from "../Redux/Actions/ActiveIngredientActions";
import { getSpecialityList } from "../Redux/Actions/SpecialityActions";
import { getDiseaseList } from "../Redux/Actions/DiseaseActions";
import { getCategory } from "../Redux/Actions/CategoryActions";
import { getUserList } from "../Redux/Actions/UserOptionsActions";

import {
  americaRoutes,
  apacRoutes,
  ecoClinicalRoutes,
  europeRoutes,
  franceRoutes,
  pricingRoutes,
  refApplicatifRoutes,
} from "../Routes";
import {
  getDataManagement,
  getFiles,
} from "../Redux/Actions/CommunFilesAction";
import Spinner from "./Loading/Spinner/Spinner";
import ErrorBoundary from "./Error/ErrorBoundary";
import NotFound from "./CommunComponents/NotFound";
import JOItemDetailsJS from "./FileManagement/PricingData/JO/JOItemDetails.js";
import { switchLoadingSelectInputs } from "../Redux/Actions/UIActions.js";

const basePath = "/admin";

const actions = [
  getDciList(),
  getActiveIngredient(),
  getSpecialityList(),
  getDiseaseList(),
  getCategory(),
  getUserList(),
];

function AdminRoute() {
  const dispatch = useDispatch();

  // files useSelector
  const { page, rowsPerPage, getAction, fileApi, attribut, order, status } =
    useSelector(({ FileSettingReducer }) => FileSettingReducer);

  let args = [fileApi, getAction, rowsPerPage, page * rowsPerPage];

  if (attribut && order) {
    let filterArg = {
      sort: {
        order: order,
        attribut: attribut,
      },
    };
    args = [...args, filterArg];
  }

  // modals useSelector
  const {
    pageModal,
    rowsPerPageModal,
    fileApiModal,
    getActionTypeModal,
    firstOpen,
    fromPagination,
  } = useSelector(
    ({ FileSettingReducer }) => FileSettingReducer.modalAttributes
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch(switchLoadingSelectInputs(true));
      await Promise.all(actions.map((action) => dispatch(action)));
      dispatch(switchLoadingSelectInputs(false));
    };

    fetchData();
  }, [dispatch]);

  const { authorizedFileList, role } = useSelector(
    ({ AuthReducer }) => AuthReducer
  );

  const authorizedFranceFileList = _.intersectionBy(
    franceRoutes,
    authorizedFileList,
    "agency"
  );

  const authorizedEuropeFileList = _.intersectionBy(
    europeRoutes,
    authorizedFileList,
    "agency"
  );

  const authorizedAmericaFileList = _.intersectionBy(
    americaRoutes,
    authorizedFileList,
    "agency"
  );

  const authorizedApacFileList = _.intersectionBy(
    apacRoutes,
    authorizedFileList,
    "agency"
  );

  useEffect(() => {
    if (getAction && !status.includes("search")) {
      const isDataManagement = [
        "dcis",
        "indexations",
        "drug-categories",
        "chemical-ingredients",
        "drug-specialities",
        "users",
      ].includes(fileApi);

      isDataManagement
        ? dispatch(getDataManagement(...args))
        : dispatch(getFiles(...args));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, rowsPerPage, getAction, fileApi]);

  useEffect(() => {
    if (getActionTypeModal) {
      if (
        !firstOpen ||
        (fromPagination && !["service-medical-rendus"].includes(fileApiModal))
      ) {
        dispatch(
          getFiles(
            fileApiModal,
            getActionTypeModal,
            rowsPerPageModal,
            pageModal * rowsPerPageModal
          )
        );
      }
    }
  }, [
    dispatch,
    pageModal,
    rowsPerPageModal,
    getActionTypeModal,
    fileApiModal,
    fromPagination,
    firstOpen,
  ]);

  return (
    <BrowserRouter>
      <NavBar
        authorizedFranceFileList={authorizedFranceFileList}
        authorizedEuropeFileList={authorizedEuropeFileList}
        authorizedAmericaFileList={authorizedAmericaFileList}
        authorizedApacFileList={authorizedApacFileList}
      />
      <ConfirmDelete />
      <ConfirmDuplicate />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            exact
            path={basePath}
            render={() => <Redirect to={`${basePath}/webboard/task`} />}
          />
          {authorizedFranceFileList?.map(({ path, Component }, index) => {
            return (
              <Route
                exact
                key={`${path}-${index}`}
                path={[
                  `${basePath}/${path}`,
                  `${basePath}/${path}/list`,
                  `${basePath}/${path}/modify/:fileId`,
                  `${basePath}/${path}/create`,
                ]}
              >
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </Route>
            );
          })}
          {authorizedEuropeFileList?.map(({ path, Component }, index) => {
            return (
              <Route
                key={`${path}-${index}`}
                path={[
                  `${basePath}/${path}`,
                  `${basePath}/${path}/list`,
                  `${basePath}/${path}/modify/:fileId`,
                  `${basePath}/${path}/create`,
                ]}
              >
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </Route>
            );
          })}
          {authorizedAmericaFileList?.map(({ path, Component }, index) => {
            return (
              <Route
                key={`${path}-${index}`}
                path={[
                  `${basePath}/${path}`,
                  `${basePath}/${path}/list`,
                  `${basePath}/${path}/modify/:fileId`,
                  `${basePath}/${path}/create`,
                ]}
              >
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </Route>
            );
          })}
          {authorizedApacFileList?.map(({ path, Component }, index) => {
            return (
              <Route
                key={`${path}-${index}`}
                path={[
                  `${basePath}/${path}`,
                  `${basePath}/${path}/list`,
                  `${basePath}/${path}/modify/:fileId`,
                  `${basePath}/${path}/create`,
                ]}
              >
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </Route>
            );
          })}
          {ecoClinicalRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}

          <Route
            exact
            path={`${basePath}/manager/viewstat_bp_upload/:id/:type`}
          >
            <ErrorBoundary>
              <JOItemDetailsJS />
            </ErrorBoundary>
          </Route>

          {pricingRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}
          {refApplicatifRoutes.map(({ path, Component }, index) => (
            <Route
              key={`${path}-${index}`}
              path={[
                `${basePath}/${path}`,
                `${basePath}/${path}/list`,
                `${basePath}/${path}/create`,
                `${basePath}/${path}/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <Component />
              </ErrorBoundary>
            </Route>
          ))}
          {role === "admin" && (
            <Route
              path={[
                `${basePath}/user`,
                `${basePath}/user/list`,
                `${basePath}/user/create`,
                `${basePath}/user/modify/:fileId`,
              ]}
            >
              <ErrorBoundary>
                <UserAccount />
              </ErrorBoundary>
            </Route>
          )}
          <Route path="*" exact component={NotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AdminRoute;
