import { crudService } from "../../Config/CrudService";
import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  EDIT_USER_OPTIONS,
  GET_USER_LIST,
} from "../../Const/ActionTypes";
import { extractHeaders } from "../../Const/ValidationFunctions";

export const addUserOptions = (data, alerte = false) => {
  return (dispatch) => {
    if (data.id === undefined) {
      crudService
        .post(`/user-options`, data)
        .then((res) => {
          let formatedUserOptions = extractHeaders(res.data);

          dispatch({
            type: EDIT_USER_OPTIONS,
            payload: formatedUserOptions,
          });
          if (alerte) {
            dispatch({
              type: SHOW_SUCCESS_MESSAGE,
              payload: "Vos choix sont enregistrés avec succès",
            });
            setTimeout(() => {
              dispatch({ type: HIDE_SUCCESS_MESSAGE });
            }, 4000);
          }
        })
        .catch((err) => {
          console.log("error ", err.message);
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload:
              "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        });
    } else {
      crudService
        .patch(`/user-options/` + data.id, data)
        .then((res) => {
          let formatedUserOptions = extractHeaders(data);

          dispatch({
            type: EDIT_USER_OPTIONS,
            payload: formatedUserOptions,
          });
          if (alerte) {
            dispatch({
              type: SHOW_SUCCESS_MESSAGE,
              payload: "Vos choix sont enregistrés avec succès",
            });
            setTimeout(() => {
              dispatch({ type: HIDE_SUCCESS_MESSAGE });
            }, 4000);
          }
        })
        .catch((err) => {
          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload:
              "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
          console.log("error message", err.message);
        });
    }
  };
};

export const getUserList = () => {
  let filter = JSON.stringify({
    fields: {
      id: true,
      firstName: true,
      lastName: true,
    },
  });
  return async (dispatch) => {
    await crudService
      .get(`/users?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_USER_LIST,
          payload: res.data.map((userEle) => {
            return {
              id: userEle.id,
              name: userEle.firstName + " " + userEle.lastName,
            };
          }),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
