/* eslint eqeqeq: "off" */

module.exports = {
  isIpAddress(value) {
    if (value != null) {
      let validIpAddress =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          value
        );
      return validIpAddress;
    } else {
      return false;
    }
  },
  isValidPassWord(value) {
    if (value != null) {
      let validPassword =
        value.match(/[0-9]/g) &&
        value.match(/[A-Z]/g) &&
        value.match(/[a-z]/g) &&
        value.match(/[^a-zA-Z\d]/g) &&
        value.length >= 8;
      return validPassword;
    } else {
      return false;
    }
  },
  isEmail(value) {
    if (value != null) {
      if (value.length > 0)
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        );
      else return true;
    } else {
      return false;
    }
  },

  isPhonenumber(str) {
    if (str.length > 1) return /^[2-9]\d{7}$/.test(str);
    else return true;
  },
  isZipCode(zipCode) {
    if (zipCode.length > 0) return /^[1-9]\d{3}$/.test(zipCode);
    else return true;
  },

  isNotEmpty(value) {
    if (value.length > 0) return true;
    else return false;
  },
  isValidHttpUrl(string) {
    let url;
    if (string && string.trim() !== "") {
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    } else {
      return true;
    }
  },

  isNotAnEmptyObject(object) {
    if (object && Object.keys(object).length) {
      return true;
    } else {
      return false;
    }
  },

  compareSection(object1, object2, sectionName = "") {
    return JSON.stringify(object1) !== JSON.stringify(object2);
  },

  extractHeaders(userOptions) {
    return Object.keys(userOptions).reduce((acc, key) => {
      const oldValue = userOptions[key];
      if (key?.includes("Header") && oldValue) {
        acc[key] = oldValue?.tableHeaders ?? [];
        acc[key + "Modal"] = oldValue?.modalHeaders ?? [];
      } else {
        acc[key] = oldValue;
      }

      return acc;
    }, {});
  },

  getExtension(fileName) {
    fileName = fileName ?? "";

    const lastDotIndex = fileName.lastIndexOf(".");
    const lastSlashIndex = fileName.lastIndexOf("/");

    if (
      lastDotIndex === -1 ||
      (lastSlashIndex !== -1 && lastDotIndex < lastSlashIndex)
    ) {
      return "pdf";
    }

    return fileName.slice(lastDotIndex + 1) ?? "pdf";
  },
};
