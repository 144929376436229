import { crudService } from "../../Config/CrudService";
import {
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  INCREMENT_COUNT,
  DECREMENT_COUNT,
  GET_SEARCHED_FILES_BY_COLUMN,
  LOADING_SEARCH_FILE,
  COUNT_SEARCH,
  CLEAR_SEARCH_FILE_STATUS,
  SET_SEARCH_FILE_STATUS,
} from "../../Const/ActionTypes";

import {
  setFileStatus,
  setModalFileStatus,
  storeSearchFilterArgs,
} from "./FileSettingActions";
import { specificFilesCount } from "./SpecificFilesSearchActions";

import { setLoading, unsetLoading } from "./UIActions";

export const displayErroSnackbar =
  (err = "") =>
  (dispatch) => {
    const errorMsg = !err
      ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
      : err;

    dispatch({
      type: SHOW_ERROR_MESSAGE,
      payload: errorMsg,
    });
    setTimeout(() => {
      dispatch({ type: HIDE_ERROR_MESSAGE });
    }, 4000);
  };

export const deleteFile = (
  api,
  actionType,
  fileName,
  file,
  callback = null
) => {
  let fileId = file.id;
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .remove(`/${api}/${fileId}`)
      .then((res) => {
        if (res.data.delete === "success") {
          if (file.keyDocumentsSection !== undefined) {
            // eslint-disable-next-line
            file.keyDocumentsSection.map((e) => {
              e.path !== "" &&
                crudService
                  .remove(`/blob-storage-azure`, { key: e.path })
                  .then((response) => {});
            });
          }

          dispatch({
            type: actionType,
            payload: fileId,
          });
          // dispatch({
          //   type: SET_FILE_STATUS,
          //   payload: { status: "get" },
          // });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "La fiche " +
              fileName +
              " ( ID : " +
              fileId +
              " ) est supprimée avec succès",
          });

          dispatch(decrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);

          if (typeof callback === "function") {
            callback();
          }
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de la suppression de fiche " +
              fileName
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const countFilesTASK = (userId) => {
  return (dispatch) => {
    crudService
      .get(`/comments/count/${userId}`)
      .then((res) => {
        dispatch(
          setFileStatus({
            maxRowSended: res.data.commentCount.count ?? 0,
            maxRowReceived: res.data.commentRecipientCount.count ?? 0,
          })
        );
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const countFiles = (api, fromModal = false, loading = false) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .get(`/${api}/count`)
      .then((res) => {
        if (fromModal) {
          dispatch(
            setModalFileStatus({
              maxRowsModal: res.data?.count ?? 0,
            })
          );

          if (loading) {
            dispatch(unsetLoading());
          }

          return;
        }

        dispatch(
          setFileStatus({
            maxRows: res.data?.count ?? 0,
          })
        );
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const countFilesWithWhere = (
  api,
  fromModal = false,
  loading = false,
  whereArgs = null
) => {
  return (dispatch) => {
    const where = whereArgs ? JSON.stringify(whereArgs) : null;
    const queryParam = "where";

    const endpoint = whereArgs
      ? `/${api}/count?${queryParam}=${where}`
      : `/${api}/count`;

    dispatch(setLoading());

    crudService
      .get(endpoint)
      .then((res) => {
        if (fromModal) {
          dispatch(
            setModalFileStatus({
              maxRowsModal: res.data?.count ?? 0,
            })
          );

          if (loading) {
            dispatch(unsetLoading());
          }

          return;
        }

        dispatch(
          setFileStatus({
            maxRows: res.data?.count ?? 0,
          })
        );
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const findFileById = (
  api,
  id,
  redirect = null,
  isReferentiel = false,
  redirectSuccess = null
) => {
  let path = `/${api}?id=${id}`;

  let include;
  if (isReferentiel) {
    if (api === "indexations")
      include = [
        {
          relation: "childIndexations",
        },
        {
          relation: "parentIndexations",
        },
      ];

    if (api === "drug-categories")
      include = [
        {
          relation: "chemicalIngred",
        },
      ];

    let filter = JSON.stringify({
      include,
    });
    path = `/${api}/${id}?filter=${filter}`;
  }

  return (dispatch) => {
    dispatch(
      setFileStatus({
        fileSlected: {},
      })
    );
    dispatch(setLoading(true));
    crudService
      .get(path)
      .then((res) => {
        const foundFile = isReferentiel ? res.data : res.data?.[0];
        if (foundFile) {
          dispatch(
            setFileStatus({
              fileSlected: foundFile,
              status: "edit",
            })
          );

          redirectSuccess && redirectSuccess(foundFile);
        } else {
          if (redirect) {
            redirect();
          }
        }
      })
      .catch((err) => {
        console.error("error", err);
        redirect && redirect();
      })
      .finally(() => {
        dispatch(unsetLoading(true));
      });
  };
};

export const getFiles = (api, action, limit, offset, filterArg = null) => {
  let pagination = "?";
  const filter = JSON.stringify(filterArg);

  if (filterArg) pagination = pagination + "filter=" + filter + "&";

  if (limit) pagination = pagination + "limit=" + limit;

  if (offset) pagination = pagination + "&offset=" + offset;

  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .get(`/${api}${pagination}`)
      .then((res) => {
        dispatch({
          type: action,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getDataManagement = (api, action, limit, offset) => {
  let include;
  if (api === "indexations")
    include = [
      {
        relation: "childIndexations",
      },
      {
        relation: "parentIndexations",
      },
    ];

  if (api === "drug-categories")
    include = [
      {
        relation: "chemicalIngred",
      },
    ];
  let filter = JSON.stringify({
    offset,
    limit,
    order: api === "users" ? ["creationDate DESC"] : ["id DESC"],
    include,
  });
  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .get(`/${api}?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: action,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const incrementCount = () => (dispatch) => {
  dispatch({ type: INCREMENT_COUNT });
};

export const decrementCount = () => (dispatch) => {
  dispatch({ type: DECREMENT_COUNT });
};

export const countSerachFileByColumn =
  (filter, agencyModel) => async (dispatch) => {
    dispatch({
      type: COUNT_SEARCH,
      payload: 0,
    });

    try {
      const { data } = await crudService.get(
        `/drug-centers/count?filter=${filter}&agencyModel=${agencyModel}`
      );

      dispatch({
        type: COUNT_SEARCH,
        payload: data?.count ?? 0,
      });

      if (!data?.count) {
        dispatch(
          displayErroSnackbar("No records with the given criteria were found.")
        );

        dispatch(setFileStatus({ status: "get" }));

        dispatch(storeSearchFilterArgs(null));

        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: [] });
      }

      return data?.count ?? 0;
    } catch (err) {
      console.log("error message", err.message);

      dispatch(displayErroSnackbar(err));
    }
  };

export const serachFileByColumn =
  (endpoint, filterArg, agencyModel, limit, offset, fromPagination) =>
  async (dispatch) => {
    const filter = JSON.stringify(filterArg);

    let count = 0;

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: true,
    });

    if (!fromPagination) {
      count = await dispatch(countSerachFileByColumn(filter, agencyModel));
    }

    if (count || fromPagination) {
      await crudService
        .get(`/${endpoint}?filter=${filter}&limit=${limit}&offset=${offset}`)
        .then((res) => {
          dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
        })
        .catch((err) => {
          console.log("error message", err.message);

          dispatch(displayErroSnackbar(err));
        });
    }

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: false,
    });
  };

export const serachFileByColumnRef =
  (endpoint, where, limit, offset, fromPagination) => async (dispatch) => {
    let include;
    if (endpoint === "indexations") {
      include = [
        {
          relation: "childIndexations",
        },
        {
          relation: "parentIndexations",
        },
      ];
    }

    if (endpoint === "drug-categories") {
      include = [
        {
          relation: "chemicalIngred",
        },
      ];
    }

    let filter = JSON.stringify({
      offset,
      limit,
      order: ["id DESC"],
      include,
      where,
    });

    let count = 0;

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: true,
    });

    if (!fromPagination) {
      count = await dispatch(specificFilesCount(filter, where));
    }

    if (count || fromPagination) {
      await crudService
        .get(`/${endpoint}?filter=${filter}`)
        .then((res) => {
          dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
        })
        .catch((err) => {
          console.log("error message", err.message);

          dispatch(displayErroSnackbar(err));
        });
    }

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: false,
    });
  };

export const serachFileById = (endpoint, id) => async (dispatch) => {
  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: true,
  });

  await crudService
    .get(`/${endpoint}?id=${id}`)
    .then((res) => {
      if (!res.data?.length) {
        dispatch(
          displayErroSnackbar("No records with the given criteria were found.")
        );
        dispatch(storeSearchFilterArgs(null));
        dispatch(setFileStatus({ status: "get" }));
      }

      dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
    })
    .catch((err) => {
      console.log("error message", err.message);

      dispatch(displayErroSnackbar(err));
    });

  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: false,
  });
};

export const searchFileByIdPathParam = (endpoint, id) => async (dispatch) => {
  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: true,
  });

  await crudService
    .get(`/${endpoint}/${id}`)
    .then((res) => {
      // if data is an array
      if (Array.isArray(res.data)) {
        if (!res.data?.length) {
          dispatch(setFileStatus({ status: "get" }));
          dispatch(storeSearchFilterArgs(null));
          return dispatch(
            displayErroSnackbar(
              "No records with the given criteria were found."
            )
          );
        }

        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      } else {
        if (!res.data) {
          dispatch(setFileStatus({ status: "get" }));
          dispatch(storeSearchFilterArgs(null));

          return dispatch(
            displayErroSnackbar(
              "No records with the given criteria were found."
            )
          );
        }

        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: [res.data] });
      }
    })
    .catch((err) => {
      console.log("error message", err.message);
      if (err.message.statusCode === 404) {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: [] });
        dispatch(setFileStatus({ status: "get" }));
        dispatch(storeSearchFilterArgs(null));
        dispatch(
          displayErroSnackbar("No records with the given criteria were found.")
        );
        return;
      }

      dispatch(displayErroSnackbar(err));
    });

  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: false,
  });
};

export const serachFileByIdRef = (endpoint, id) => async (dispatch) => {
  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: true,
  });

  let path = `/${endpoint}?id=${id}`;
  let include;

  if (endpoint === "indexations")
    include = [
      {
        relation: "childIndexations",
      },
      {
        relation: "parentIndexations",
      },
    ];

  if (endpoint === "drug-categories")
    include = [
      {
        relation: "chemicalIngred",
      },
    ];
  let filter = JSON.stringify({
    include,
  });

  path = `/${endpoint}/${id}?filter=${filter}`;

  await crudService
    .get(path)
    .then((res) => {
      if (!res.data) {
        dispatch(
          displayErroSnackbar("No records with the given criteria were found.")
        );
        dispatch(storeSearchFilterArgs(null));
        dispatch(setFileStatus({ status: "get" }));
        return;
      }

      dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: [res.data] });
    })
    .catch((err) => {
      console.log("error message", err.message);

      dispatch(displayErroSnackbar(err));
    });

  dispatch({
    type: LOADING_SEARCH_FILE,
    payload: false,
  });
};

export const setSearchFileStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_FILE_STATUS,
    payload: status,
  });
};

export const clearSearchedFiles = () => (dispatch) => {
  dispatch({ type: CLEAR_SEARCH_FILE_STATUS });
};
