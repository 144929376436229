/* eslint import/no-anonymous-default-export: "off" */

import { GET_ITALY_FILE_LIST, ADD_FILE_ITALY, EDIT_FILE_ITALY, DELETE_FILE_ITALY } from "../../Const/ActionTypes";

const initialState = {
  listItaly: [],
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ITALY_FILE_LIST: {
      return Object.assign({}, state, {
        listItaly: action.payload,
      });
    }
    case EDIT_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [action.payload, ...state.listItaly.filter((element) => element.id !== action.payload.id)],
       });
    }
    case ADD_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [action.payload].concat(state.listItaly),
       });
    }
    case DELETE_FILE_ITALY: {
      return Object.assign({}, state, {
        listItaly: [...state.listItaly.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
