/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_NIHTA_FILE_LIST,
  ADD_FILE_NIHTA,
  EDIT_FILE_NIHTA,
  DELETE_FILE_NIHTA,
} from "../../Const/ActionTypes";

const initialState = {
  listNihta: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NIHTA_FILE_LIST: {
      return Object.assign({}, state, {
        listNihta: action.payload,
      });
    }

    case ADD_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [action.payload].concat(state.listNihta),
      });
    }

    case EDIT_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [
          action.payload,
          ...state.listNihta.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }

    case DELETE_FILE_NIHTA: {
      return Object.assign({}, state, {
        listNihta: [
          ...state.listNihta.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
