/* eslint import/no-anonymous-default-export: "off" */
import {
  GET_KEYWORDS_LIST,
  ADD_KEYWORDS,
  EDIT_KEYWORDS,
} from '../../Const/ActionTypes';
const initialState = {
  KeywordsList: [],
};
export default function (state = initialState, action) {
  if (action.type === GET_KEYWORDS_LIST) {
    return Object.assign({}, state, {
      KeywordsList: action.payload,
    });
  }
  if (action.type === ADD_KEYWORDS) {
    return Object.assign({}, state, {
      KeywordsList: [action.payload].concat(state.KeywordsList),
    });
  }
  if (action.type === EDIT_KEYWORDS) {
    return Object.assign({}, state, {
      KeywordsList: [
        action.payload,
        ...state.KeywordsList.filter(
          element => element.id !== action.payload.id,
        ),
      ],
    });
  }

  return state;
}
