import {
  SIGNIN_USER_SUCCESS /*SIGN_OUT_USER*/,
  LOADING_GET_PROFILE,
  GET_USER_OPTIONS,
  EDIT_USER_OPTIONS,
  LOADING_LOG_OUT,
} from "../../Const/ActionTypes";
import { crudService } from "../../Config/CrudService";
import { extractHeaders } from "../../Const/ValidationFunctions";

export const getUserInfo = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_GET_PROFILE, payload: true });
    crudService
      .get(`/users/me`)
      .then((res) => {
        if (res) {
          dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: res.data,
          });
          if (res.data.userOptions) {
            let formatedUserOptions = extractHeaders(res.data.userOptions);

            dispatch({
              type: GET_USER_OPTIONS,
              payload: formatedUserOptions,
            });
          } else {
            dispatch({
              type: EDIT_USER_OPTIONS,
              payload: { userId: res.data.id },
            });
          }
          dispatch({ type: LOADING_GET_PROFILE, payload: false });
        }
      })
      .catch((err) => {
        console.log(err, "****err*****");
      });
  };
};
export const refreshUserToken = () => {
  return (dispatch) => {
    crudService
      .get(`/users/refresh-token`)
      .then((res) => {
        if (res?.data) {
          window.localStorage.setItem("token", res.data);
        }
      })
      .catch((err) => {
        console.log(err, "****err*****");
      });
  };
};

export const userLogOut = () => {
  return (dispatch) => {
    dispatch({ type: LOADING_LOG_OUT, payload: true });
    let apiEndpoint = `/logout`;
    crudService
      .get(apiEndpoint)
      .then((res) => {
        if (res?.data?.redirectUrl) {
          localStorage.removeItem("token");
          window.location.href = res.data.redirectUrl;
          // dispatch({ type: LOADING_LOG_OUT, payload: false });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      })
      .finally(() => {
        window.scrollTo(0, 0);
      });
  };
};
