import {
  ADD_FILE_PRICING_BRESIL,
  DELETE_FILE_PRICING_BRESIL,
  EDIT_FILE_PRICING_BRESIL,
  GET_PRICING_BRESIL_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingBresil: [],
  
};

export default function pricingBresil(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_BRESIL_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingBresil: action.payload,
      });
    }
    case EDIT_FILE_PRICING_BRESIL: {
      return Object.assign({}, state, {
        listPricingBresil: state.listPricingBresil.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

       });
    }
    case ADD_FILE_PRICING_BRESIL: {
      return Object.assign({}, state, {
        listPricingBresil: [action.payload].concat(state.listPricingBresil),
       });
    }

    case DELETE_FILE_PRICING_BRESIL: {
      return Object.assign({}, state, {
        listPricingBresil: [
          ...state.listPricingBresil.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
