import React, { Component } from "react";
import { connect } from "react-redux";
import { usersTableHead } from "../../Const/selectListValues";

class UsersTableHead extends Component {
  render() {
    return (
      <thead className="thead dashboard__thead">
        <tr>
          {usersTableHead.map((e, index) => (
            <td id="id" key={index}>
              <div className="dashboard__head">{e.label}</div>
            </td>
          ))}

          <td />
        </tr>
      </thead>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
})(UsersTableHead);
