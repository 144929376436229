import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getJoById } from "../../../../Redux/Actions/JOActions";

class TableCellExecutedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedAlert: this.props.fileItem.boitePresentation.bp_alert_email,
    };
  }
  render() {
    const { fileItem, columnName, handleEditEmailAlert } = this.props;

    const handleCheckEmailAlert = (event) => {
      this.setState({ checkedAlert: event.target.checked }, () => {
        handleEditEmailAlert(
          {
            bpIdList: [
              {
                bp_id: fileItem.boitePresentation.bp_id,
                bp_alert_email: this.state.checkedAlert ? 1 : 0,
              },
            ],
          },
          this.props.id
        );
      });
    };

    return (
      <td>
        <div className="dashboard__item">
          {columnName.label === "alerte email" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                className="checkbox _icon-checkbox"
                type="checkbox"
                checked={this.state.checkedAlert}
                onChange={(e) => handleCheckEmailAlert(e)}
                name="emailAlert"
              />
            </div>
          ) : columnName.label === "CIP13" ? (
            fileItem.boitePresentation.bp_cip13
          ) : columnName.label === "UCD13" ? (
            fileItem.boitePresentation.bp_ucd13
          ) : columnName.label === "Présentation / Conditionnement" ? (
            fileItem.boitePresentation.bp_presentation
          ) : columnName.label === "Date prix RETRO(JO)" ? (
            moment(fileItem.prix_date_prix_retro)?.format("YYYY-MM-DD")
          ) : columnName.label === "Date prix SS(JO)" ? (
            moment(fileItem.prix_date_prix_ss)?.format("YYYY-MM-DD")
          ) : columnName.label === "Date prix T2A(JO)" ? (
            moment(fileItem.prix_date_prix_t2a)?.format("YYYY-MM-DD")
          ) : columnName.label === "Variation de prix SS" ? (
            fileItem.prix_variation_ss
          ) : columnName.label === "Variation Prix T2A(JO)" ? (
            fileItem.prix_variation_t2a
          ) : columnName.label === "Variation Prix Retro(JO)" ? (
            fileItem.prix_variation_retro
          ) : (
            ""
          )}
        </div>
      </td>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { getJoById })(TableCellExecutedList);
