/* eslint import/no-anonymous-default-export: "off" */

import {  GET_LIST_PATIENT_POPULATION } from "../../Const/ActionTypes";

const initialState = {
  patientPopulationList: [],
 
 };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_PATIENT_POPULATION: {
      return Object.assign({}, state, {
        patientPopulationList: action.payload,
      });
    }
   
    default:
      return state;
  }
}
