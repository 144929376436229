import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  HeaderItemsBoitesPresentationExecutedList,
  HeaderItemsBoitesPresentationExecutedListPt,
  HeaderItemsBoitesPresentationExecutedListPv,
  HeaderItemsBoitesPresentationExecutedListRetro,
} from "../../../../Const/selectListValues";
import JoTableHead from "./JoTableHead";
import JOHeader from "./JOHeader";
import _ from "lodash";
import excelIcon from "../../../../images/excel.svg";
import ExecutedListFileListItem from "./ExecutedListFileListItem";
import { useDispatch, useSelector } from "react-redux";
import { editEmailAlert } from "../../../../Redux/Actions/BoitePresentationActions";
import { displayNotFoundPage } from "../../../../Redux/Actions/FileSettingActions";
import SkeletonSectionItem from "../../../Loading/Skeletons/SkeletonSectionItem";
import { crudService } from "../../../../Config/CrudService";
import { getJoById } from "../../../../Redux/Actions/JOActions";

const JOItemDetails = () => {
  const { type, id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const { joSelected } = useSelector(({ JOReducer }) => JOReducer);
  const { loading } = useSelector(({ UIReducer }) => UIReducer);

  const handleEditEmailAlert = (data, id) => {
    dispatch(editEmailAlert(data, id));
  };

  const [joHeaders] = useState(
    type === "pv"
      ? _.reject(
          HeaderItemsBoitesPresentationExecutedList.concat(
            HeaderItemsBoitesPresentationExecutedListPv
          ),
          {
            label: "UCD13",
          }
        )
      : type === "pr"
      ? HeaderItemsBoitesPresentationExecutedList.concat(
          HeaderItemsBoitesPresentationExecutedListRetro
        )
      : HeaderItemsBoitesPresentationExecutedList.concat(
          HeaderItemsBoitesPresentationExecutedListPt
        )
  );

  useEffect(() => {
    const redirect = () => {
      history.push(`/admin/manager/bp_upload`);
      dispatch(displayNotFoundPage(id));
    };
    dispatch(getJoById(id, redirect));
  }, [id, dispatch, history]);

  const renderList = () => {
    return joSelected?.prix_list?.map((row, index) => (
      <ExecutedListFileListItem
        joHeaders={joHeaders}
        row={row}
        key={index}
        id={id}
        handleEditEmailAlert={handleEditEmailAlert}
      />
    ));
  };

  return (
    <section className="settings settings_line">
      <div className="settings__container _container">
        <div className="settings__wrapper">
          <JOHeader id={id} type={type} />
          {!!loading ? (
            <SkeletonSectionItem />
          ) : (
            <>
              <div className="settings__spollers">
                <div data-spollers data-one-spoller className="spollers"></div>
              </div>
              <div className="spollers__item" style={{ padding: "20px" }}>
                <div className="form-settings__column">
                  <div className="form-settings__label">
                    <strong>File</strong>
                  </div>
                  <div
                    className="form-settings__column"
                    onClick={() => {
                      if (joSelected?.excel?.length) {
                        crudService
                          .get(
                            `/blob-storage-azure?key=${
                              joSelected?.excel
                            }&container=${"prismaccess-uploads"}`
                          )
                          .then((response) => {
                            const urlFile = response.data;
                            let a = document.createElement("a");
                            a.download = joSelected?.excel?.substring(
                              joSelected?.excel?.lastIndexOf("/") + 1
                            );
                            a.href = urlFile;
                            a.click();
                          });
                      }
                    }}
                  >
                    <span>
                      <img
                        alt="excel"
                        src={excelIcon}
                        style={{
                          height: "30px",
                          width: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="spollers__item" style={{ padding: "20px" }}>
                <div className="form-settings__label">
                  Boites Présentation Executed List
                </div>
                <div style={{ marginTop: "20px" }}>
                  <table className="dashboard__table executedList_table">
                    <JoTableHead
                      joHeaders={joHeaders}
                      handleEditEmailAlert={handleEditEmailAlert}
                      prixList={joSelected?.prix_list}
                    />
                    <tbody className="dashboard__body">{renderList()}</tbody>
                  </table>
                </div>
              </div>
              <div className="spollers__item" style={{ padding: "20px" }}>
                <div className="form-settings__column">
                  <div className="form-settings__label">
                    {" "}
                    <strong>Error</strong>
                  </div>
                  <div className="form-settings__column">
                    {type === "pv" ? (
                      <strong>CIP 13</strong>
                    ) : (
                      <strong>UCD 13</strong>
                    )}
                    <span
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {" "}
                      {joSelected?.error_id?.toString()?.substring(1)}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default JOItemDetails;
