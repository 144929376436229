export const reactSelectValues = [
  "fk_chemical_ingrediant_id",
  "fk_category_id",
  "fk_drug_speciality_id",
  "fk_dci_id",
  "indexation_id-fr",
  "indexation_id-en",
  "indexation_id-de",
  "mk_orphelin",
  "hta_decision",
  "reimbursement_decision",
  "trs_category",
  "fkChemicalIngredientId",
  "dci_new_id",
  "category_id",
  "drug_name",
  "dci",
  "dci_new",
];

export const defaultSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "HTA decision",
    value: "hta_decision",
  },
  {
    label: "Indexation FR",
    value: "indexation_id-fr",
  },
  {
    label: "Indexation EN",
    value: "indexation_id-en",
  },
  {
    label: "Indexation DE",
    value: "indexation_id-de",
  },
  {
    label: "Reimbursement",
    value: "reimbursement_decision",
  },
  {
    label: "Firm",
    value: "firm",
  },
  {
    label: "MK orphelin",
    value: "mk_orphelin",
  },
  {
    label: "Inn",
    value: "fk_chemical_ingrediant_id",
  },
  {
    label: "Catégorie",
    value: "fk_category_id",
  },
  {
    label: "Drug name",
    value: "fk_drug_speciality_id",
  },
  {
    label: "DCI",
    value: "fk_dci_id",
  },
];

export const boitePresentationSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "UCD",
    value: "bp_ucd",
  },
  {
    label: "CIS",
    value: "bp_cis",
  },
  {
    label: "CIP13",
    value: "bp_cip13",
  },
  {
    label: "ATC Pro",
    value: "bp_atc_pro",
  },
  {
    label: "Présentation / Conditionnement",
    value: "bp_presentation",
  },
];

export const ansmSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "ID ANSM",
    value: "ansm_id",
  },
  {
    label: "Libellé du groupe générique ANSM",
    value: "libelle",
  },
  {
    label: "Libellé DCI du groupe générique ANSM",
    value: "libelle_dci",
  },
];

export const tfrSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "ID TFR",
    value: "id_tfr",
  },
  {
    label: "ID ANSM",
    value: "ansm_id",
  },
  {
    label: "Forme galénique",
    value: "forme_galenique",
  },
  {
    label: "Unité",
    value: "unite",
  },
  {
    label: "Groupe générique ANSM",
    value: "libelle",
  },
  {
    label: "Groupe générique DCI ANSM",
    value: "libelle_dci",
  },
];

export const biosimilairesSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "ID ANSM Biosimilaire",
    value: "ansm_biosimilaire_id",
  },
  {
    label: "Médicament de référence",
    value: "medicament_reference",
  },
  {
    label: "Substance active",
    value: "substance_active",
  },
];

export const nctsInfosSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "NCT Number",
    value: "nct_id",
  },
  {
    label: "Catégorie",
    value: "category_id",
  },
  {
    label: "Ingrédiant actif",
    value: "dci_new_id",
  },
  {
    label: "Trial name",
    value: "trial_name",
  },
  {
    label: "Type",
    value: "ec_type",
  },
  {
    label: "Comparaison",
    value: "ec_comparateur",
  },
];

export const indicationJoSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "Catégorie",
    value: "trs_category",
  },
  {
    label: "Indication",
    value: "indication",
  },
];

export const ecoEvalSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "Catégorie",
    value: "trs_category",
  },
  {
    label: "Indexation FR",
    value: "indexation_id-fr",
  },
  {
    label: "Indexation EN",
    value: "indexation_id-en",
  },
  {
    label: "Indexation DE",
    value: "indexation_id-de",
  },
  {
    label: "Drug name",
    value: "drug_name",
  },
  {
    label: "Code ATC",
    value: "atc",
  },
  {
    label: "Ingrédiant actif",
    value: "dci_new",
  },
  {
    label: "DCI",
    value: "dci",
  },
];

export const categorySearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "Nom catégories",
    value: "name",
  },
  {
    label: "Ingrédient actif",
    value: "fkChemicalIngredientId",
  },
  {
    label: "Code ATC",
    value: "atc",
  },
];

export const chemicalIngredientsSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "Nom EN",
    value: "nameEn",
  },
  {
    label: "Nom FR",
    value: "nameFr",
  },
];

export const diseasesSearchOptionsList = [
  {
    label: "ID",
    value: "id",
  },
  {
    label: "Nom EN",
    value: "index_en",
  },
  {
    label: "Nom FR",
    value: "index_fr",
  },
  {
    label: "Nom DE",
    value: "index_de",
  },
];

export const filterDefaultSearchOptionsList = (filter) =>
  defaultSearchOptionsList.filter((elemement) => elemement.label !== filter);
