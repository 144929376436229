/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_GBA_FILE_LIST,
  ADD_FILE_GBA,
  EDIT_FILE_GBA,
  DELETE_FILE_GBA,
} from "../../Const/ActionTypes";

const initialState = {
  listGba: [],
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GBA_FILE_LIST: {
      return Object.assign({}, state, {
        listGba: action.payload,
      });
    }
    case EDIT_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: state.listGba.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
       });
    }
    case ADD_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: [action.payload].concat(state.listGba),
       });
    }

    case DELETE_FILE_GBA: {
      return Object.assign({}, state, {
        listGba: [
          ...state.listGba.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
