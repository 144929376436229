import React from "react";
import { Helmet } from "react-helmet";

function HelmetComponent({ title }) {
  const upperCasedTitle = title?.charAt(0)?.toUpperCase() + title?.slice(1);

  return (
    <Helmet>
      <title>{upperCasedTitle ?? ""} - Administrateur</title>
    </Helmet>
  );
}

export default HelmetComponent;
