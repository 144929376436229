import React from "react";

function ConfirmDeleteReferentiel({ deleteFunction, toggleModal, text, id }) {
  const confirmDelete = () => {
    deleteFunction();

    window.scrollTo(0, 0);
  };

  const message = `Voulez-vous supprimer ${text} avec l'ID ${id}`;

  return (
    <div className="action-popup action-popup-edit _active">
      <div className="action-popup__wrapper">
        <div className="action-popup__content">
          <p className="action-popup__title">Êtes-vous sûr?</p>
          <p className="action-popup__text">{message}</p>
          <div className="action-popup__buttons">
            <button
              className="action-popup__button action-popup__button_continue button"
              onClick={() => confirmDelete()}
            >
              Continuer
            </button>
            <button
              className="action-popup__button action-popup__button_close button button-empty"
              onClick={() => toggleModal()}
            >
              Quitter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteReferentiel;
