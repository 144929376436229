/* eslint import/no-anonymous-default-export: "off" */

import {
  EDIT_FILE_CEESP,
  GET_CEESP_FILE_LIST,
  ADD_FILE_CEESP,
  DELETE_FILE_CEESP,
} from '../../Const/ActionTypes';

const initialState = {
  listCEESP: [],
 };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CEESP_FILE_LIST: {
      return Object.assign({}, state, {
        listCEESP: action.payload,
      });
    }
    case EDIT_FILE_CEESP: {
      return Object.assign({}, state, {
        listCEESP: [
          action.payload,
          ...state.listCEESP.filter(
            element => element.id !== action.payload.id,
          ),
        ],
       });
    }
    case ADD_FILE_CEESP: {
      return Object.assign({}, state, {
        listCEESP: [action.payload].concat(state.listCEESP),
       });
    }
    case DELETE_FILE_CEESP: {
      return Object.assign({}, state, {
        listCEESP: [
          ...state.listCEESP.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
