import { GET_STUDIES } from "../../Const/ActionTypes";

const initialState = {
  listStudies: [],
};

export default function StudiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STUDIES: {
      return Object.assign({}, state, {
        listStudies: action.payload,
      });
    }

    default:
      return state;
  }
}
