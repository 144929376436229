import {
  SET_FILE_STATUS,
  SET_FILE_MODAL_HEADERS,
  SET_FILE_HEADERS,
  SET_ALL_FILE_HEADERS,
  SEELECT_FILE_MODAL_HEADERS,
  SEELECT_ALL_FILE_MODAL_HEADERS,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_MODAL_FILE_STATUS,
  CLEAR_MODAL_FILE_STATUS,
  STORE_SELECTED_ITEM,
  STORE_STATE,
  SET_SORTED_FILE_LIST,
  SET_SEARCH_FILTER_ARGS,
} from "../../Const/ActionTypes";

export const setFileStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILE_STATUS,
      payload: status,
    });
  };
};

export const setFileModalHeaders = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILE_MODAL_HEADERS,
      payload: payload,
    });
  };
};

export const setFileHeaders = (item) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILE_HEADERS,
      payload: item,
    });
  };
};

export const setAllFileHeaders = (headers) => {
  return (dispatch) => {
    dispatch({
      type: SET_ALL_FILE_HEADERS,
      payload: headers,
    });
  };
};

export const handleSelectFileModalHeaders = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SEELECT_FILE_MODAL_HEADERS,
      payload: payload,
    });
  };
};

export const selectAllFileModalHeaders = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SEELECT_ALL_FILE_MODAL_HEADERS,
      payload: payload,
    });
  };
};

export const displayNotFoundPage = (fileId) => (dispatch) => {
  const errorMsg = `La fichie avec l'ID : ${fileId} n'a pas été trouvée`;

  dispatch({
    type: SHOW_ERROR_MESSAGE,
    payload: errorMsg,
  });

  setTimeout(() => {
    dispatch({ type: HIDE_ERROR_MESSAGE });
  }, 4000);
};

export const setModalFileStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_MODAL_FILE_STATUS,
    payload: status,
  });
};

export const clearModalFileStatus = () => (dispatch) => {
  dispatch({
    type: CLEAR_MODAL_FILE_STATUS,
  });
};

export const setFileSort = (payload) => (dispatch) => {
  dispatch({
    type: SET_SORTED_FILE_LIST,
    payload,
  });
};

export const storeSelectedItem = (payload) => (dispatch) => {
  dispatch({
    type: STORE_SELECTED_ITEM,
    payload,
  });
};

export const storeState = (payload) => (dispatch) => {
  dispatch({
    type: STORE_STATE,
    payload,
  });
};

export const storeSearchFilterArgs = (payload) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_FILTER_ARGS,
    payload,
  });
};
