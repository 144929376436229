import React, { Component } from "react";
import WarningIcon from "../../images/warning-icon.svg";

import Styles from "./Error.module.css";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className="page">
          <section className="dashboard">
            <div className="dashboard__container _container">
              <div className="dashboard__wrapper">
                <div className={Styles["error-boundray__container"]}>
                  <div className={Styles["error-boundray__container-content"]}>
                    <img src={WarningIcon} width="20%" alt="warning icon" />
                  </div>

                  <div className={Styles["error-boundray__container-content"]}>
                    <h1>
                      Something went{" "}
                      <span style={{ color: "#ff763f" }}>wrong.</span>
                    </h1>
                  </div>

                  <div className={Styles["error-boundray__container-content"]}>
                    <p>This page may be broken.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
