/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_SMC_FILE_LIST,
  ADD_FILE_SMC,
  EDIT_FILE_SMC,
  DELETE_FILE_SMC,
} from "../../Const/ActionTypes";

const initialState = {
  listSMC: [],
   
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SMC_FILE_LIST: {
      return Object.assign({}, state, {
        listSMC: action.payload,
      });
    }
    case EDIT_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [
          action.payload,
          ...state.listSMC.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
       });
    }
    case ADD_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [action.payload].concat(state.listSMC),
       });
    }
    case DELETE_FILE_SMC: {
      return Object.assign({}, state, {
        listSMC: [
          ...state.listSMC.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
