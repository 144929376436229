/* eslint-disable */
import {
  GET_TRF_FILE_LIST,
  ADD_FILE_TRF,
  EDIT_FILE_TFR,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_DUPLICATION_ID,
  GET_SEARCHED_FILES_BY_COLUMN,
  SET_FILE_STATUS,
} from "../../Const/ActionTypes";
import _ from "lodash";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getTRFFiles = () => {
  return (dispatch) => {
    crudService
      .get(`/tfrs`)
      .then((res) => {
        dispatch({
          type: GET_TRF_FILE_LIST,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalTRFSubmit = (data, duplication = false) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/tfrs`, data)
      .then((res) => {
        dispatch({
          type: ADD_FILE_TRF,
          payload: res.data,
        });

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche TFR est dupliquée avec succès" +
              " ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche TFR est ajoutée avec succès" +
              " ( ID : " +
              res.data.id +
              " )",
        });
        dispatch(
          getTFRPDF("Tfr", data, res.data.id, "tfrs", EDIT_FILE_TFR, [
            {
              name: "tfrSection",
              path: "joLinkPdf",
              link: "joLink",
              listOld: "oldTfrSection",
            },
            {
              name: "tfrsInformationsSection",
              listOld: "oldTfrsInformationsSection",
              data: [
                {
                  path: "linkJoPdfCreation",
                  link: "linkJoCreation",
                  file: "linkJoFileCreation",
                },
                {
                  path: "linkJoPdfDelete",
                  link: "linkJoDelete",
                  file: "linkJoFileDelete",
                },
              ],
            },
          ])
        );
        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.error(err);
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors d'ajout d'ne nouvelle fiche TFR"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalTRFEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/tfrs/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_TFR,
          payload: res.data,
        });
        dispatch(
          getTFRPDF("Tfr", data, res.data.id, "tfrs", EDIT_FILE_TFR, [
            {
              name: "tfrSection",
              path: "joLinkPdf",
              link: "joLink",
              listOld: "oldTfrSection",
            },
            {
              name: "tfrsInformationsSection",
              listOld: "oldTfrsInformationsSection",
              data: [
                {
                  path: "linkJoPdfCreation",
                  link: "linkJoCreation",
                  file: "linkJoFileCreation",
                },
                {
                  path: "linkJoPdfDelete",
                  link: "linkJoDelete",
                  file: "linkJoFileDelete",
                },
              ],
            },
          ])
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,

          payload:
            sectionName === undefined
              ? "La fiche TFR ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.error(err);
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche TFR"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getTFRPDF = (
  agencyModel,
  data,
  fileId,
  api,
  EDIT_TYPE,
  sectionName
) => {
  return (dispatch) => {
    if (!(data.id && data.id !== fileId)) {
      let enableUpload = false;
      let pdfToDelete = [];
      /** if edit function  */
      if (data.id !== undefined) {
        sectionName.map((section) => {
          if (data[section.name] && data[section.listOld]) {
            if (
              (typeof data[section.name] !== "object" ||
                Array.isArray(data[section.name])) &&
              !section.data
            ) {
              pdfToDelete = _.map(
                _.differenceBy(
                  data[section.listOld],
                  data[section.name],
                  section.path
                ),
                section.path
              );
            } else {
              pdfToDelete = section.data.map((dataEle) => {
                if (
                  data[section.name][dataEle.path] !==
                  data[section.listOld][section.name][dataEle.path]
                ) {
                  return data[section.listOld][section.name][dataEle.path];
                }
              });
            }
            pdfToDelete
              .filter((e) => e)
              .map((e) => {
                crudService.remove(`/blob-storage-azure`, { key: e });
                enableUpload = true;
              });
          }
        });
      }
      let formatedUrls = [];
      const formData = new FormData();
      sectionName.map((section) => {
        if (data[section.name]) {
          // check if type of section is an array
          if (
            (typeof data[section.name] !== "object" ||
              Array.isArray(data[section.name])) &&
            !section.data
          ) {
            data[section.name].map((element, index) => {
              if (
                element[section.link]?.length &&
                element.file === undefined &&
                element[section.path] === ""
              ) {
                const fileExtension = element[section.link].replace(
                  /^.*\./,
                  ""
                );
                formatedUrls.push({
                  name: index + 1 + "_link." + fileExtension,
                  path: element[section.link],
                  index: section.name + index,
                });
                enableUpload = true;
              }
              if (element.file !== undefined) {
                const myNewFile = new File(
                  [element.file],
                  index + 1 + "_" + element.file.name
                );

                formData.append(section.name + index, myNewFile);
                enableUpload = true;
              }
              return null;
            });
          } else {
            section.data.map((dataEle, index) => {
              if (
                data[section.name][dataEle.link]?.length &&
                data[section.name][dataEle.file] === undefined &&
                data[section.name][dataEle.path] === ""
              ) {
                const fileExtension = data[section.name][dataEle.link].replace(
                  /^.*\./,
                  ""
                );
                formatedUrls.push({
                  name: index + dataEle.path + 1 + "_link." + fileExtension,
                  path: data[section.name][dataEle.link],
                  index: section.name + dataEle.path + 1,
                });
                enableUpload = true;
              }
              if (data[section.name][dataEle.file] !== undefined) {
                const myNewFile = new File(
                  [data[section.name][dataEle.file]],
                  dataEle.path + 1 + "_" + data[section.name][dataEle.file].name
                );
                formData.append(section.name + dataEle.path + 1, myNewFile);
                enableUpload = true;
              }
              return null;
            });
          }
        }
      });

      formData.append(
        "urlsList",
        JSON.stringify({
          data: formatedUrls,
        })
      );
      if (enableUpload) {
        crudService
          .post(`/blob-storage-azure/${agencyModel}/${fileId}`, formData)
          .then((s3Response) => {
            sectionName.map((section) => {
              if (data[section.name]) {
                if (
                  (typeof data[section.name] !== "object" ||
                    Array.isArray(data[section.name])) &&
                  !section.data
                ) {
                  data[section.name] = data[section.name].map(
                    (oldEle, index) => {
                      delete oldEle.file;
                      let pathData = s3Response.data.find(
                        (e) => e.index === section.name + index
                      );

                      let formatedObject = {
                        ...oldEle,
                        [section.path]: pathData?.key ?? oldEle[section.path],
                      };
                      return formatedObject;
                    }
                  );
                } else {
                  const sectionObject = section.name;
                  let formatedObject = data[sectionObject];
                  section.data.map((dataEle) => {
                    let pathData = s3Response.data.find(
                      (e) => e.index === sectionObject + dataEle.path + 1
                    );

                    formatedObject = {
                      ...formatedObject,
                      [dataEle.path]:
                        pathData?.key ?? data[sectionObject][dataEle.path],
                      [dataEle.file]: undefined,
                    };
                  });

                  data[sectionObject] = formatedObject;
                }
              }
            });

            crudService
              .put(`/${api}/${fileId}`, {
                ...data,
              })
              .then((res) => {
                dispatch({
                  type: SET_FILE_STATUS,
                  payload: { fileSlected: res.data },
                });
                dispatch({
                  type: EDIT_TYPE,
                  payload: res.data,
                });
              })
              .catch((err) => {
                console.log("error message", err.message);
              });
          });
      }
    }
  };
};

export const searchTfr = (limit = 5, offset = 0, where = null) => {
  let querParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    querParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/tfrs?${querParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
