import React from "react";

import Styles from "./Skeleton.module.css";

function SkeletonSectionItem() {
  const randomArray = Array(2).fill();

  return (
    <div className={Styles["skeleton-container"]}>
      <div className={`${Styles["card-header"]} ${Styles["is-loading"]}`}>
        <div className={Styles["content-header"]}>
          <p className={Styles["content-p-tags"]}></p>
        </div>

        <div className={Styles["content-header"]}>
          <p className={Styles["content-p-tags"]}></p>
        </div>
      </div>

      {randomArray.map((element, index) => (
        <div
          key={index}
          className={`${Styles["card"]} ${Styles["is-loading"]} `}
        >
          <div className={Styles["content"]}>
            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>

            <p className={Styles["content-p-tags"]}></p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SkeletonSectionItem;
