import {
  LOADING_SELECT_INPUTS,
  SET_LOADING,
  SET_LOADING_FIND_BY_ID,
  UNSET_LOADING,
  UNSET_LOADING_FIND_BY_ID,
} from "../../Const/ActionTypes";

export const setLoading =
  (findById = false) =>
  (dispatch) => {
    dispatch({
      type: findById ? SET_LOADING_FIND_BY_ID : SET_LOADING,
    });
  };

export const unsetLoading =
  (findById = false) =>
  (dispatch) => {
    dispatch({
      type: findById ? UNSET_LOADING_FIND_BY_ID : UNSET_LOADING,
    });
  };

export const switchLoadingSelectInputs = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: LOADING_SELECT_INPUTS,
  });
};
