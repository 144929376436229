/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_NHSA_FILE_LIST,
  ADD_FILE_NHSA,
  EDIT_FILE_NHSA,
  DELETE_FILE_NHSA,
} from "../../Const/ActionTypes";

const initialState = {
  listNhsa: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NHSA_FILE_LIST: {
      return Object.assign({}, state, {
        listNhsa: action.payload,
      });
    }

    case ADD_FILE_NHSA: {
      return Object.assign({}, state, {
        listNhsa: [action.payload].concat(state.listNhsa),
      });
    }
    case EDIT_FILE_NHSA: {
      return Object.assign({}, state, {
        listNhsa: [
          action.payload,
          ...state.listNhsa.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }
    case DELETE_FILE_NHSA: {
      return Object.assign({}, state, {
        listNhsa: [
          ...state.listNhsa.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
