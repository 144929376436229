/* eslint-disable  */
import {
  GET_FILE_BIOSIMILAIRE,
  ADD_FILE_BIOSIMILAIRE,
  EDIT_FILE_BIOSIMILAIRE,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_DUPLICATION_ID,
  GET_SEARCHED_FILES_BY_COLUMN,
  SET_FILE_STATUS,
} from "../../Const/ActionTypes";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";
export const getBiosimilaireFiles = () => {
  return (dispatch) => {
    crudService
      .get(`/biosimilaires`)
      .then((res) => {
        dispatch({
          type: GET_FILE_BIOSIMILAIRE,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalBiosimilaireSubmit = (data, duplication = false) => {
  let mutatedData = data;

  if (duplication) {
    const { biosimilaireInformationsSection } = mutatedData;

    if (biosimilaireInformationsSection?.biosimilaireAnsmId) {
      mutatedData = {
        ...data,
        biosimilaireInformationsSection: {
          ...biosimilaireInformationsSection,
          biosimilaireAnsmId: null,
        },
      };
    }
  }

  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/biosimilaires`, mutatedData)
      .then((res) => {
        dispatch({
          type: ADD_FILE_BIOSIMILAIRE,
          payload: res.data,
        });

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }
        dispatch(
          getBiosimilairePDF(
            "Biosimilaires",
            data,
            res.data.id,
            "biosimilaires",
            EDIT_FILE_BIOSIMILAIRE
          )
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche BIOSIMILAIRES est dupliquée avec succès ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche BIOSIMILAIRES est ajoutée avec succès ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors d'ajout d'une nouvelle fiche BIOSIMILAIRE"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });

        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalBiosimilaireEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/biosimilaires/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_BIOSIMILAIRE,
          payload: res.data,
        });

        dispatch(
          getBiosimilairePDF(
            "Biosimilaires",
            data,
            res.data.id,
            "biosimilaires",
            EDIT_FILE_BIOSIMILAIRE
          )
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload:
            sectionName === undefined
              ? "La fiche BIOSIMILAIRES ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche BIOSIMILAIRE"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getBiosimilairePDF = (
  agencyModel,
  data,
  fileId,
  api,
  EDIT_TYPE
) => {
  return (dispatch) => {
    if (!(data.id && data.id !== fileId)) {
      let enableUpload = false;
      /** if edit function  */
      if (data.id !== undefined) {
        if (
          data.biosimilaireInformationsSection &&
          data.oldBiosimilaireInformationsSection
        ) {
          let pdfToDelete = [];
          if (
            data.biosimilaireInformationsSection.ansmCreationPdf !==
            data.oldBiosimilaireInformationsSection.ansmCreationPdf
          ) {
            pdfToDelete.push(
              data.oldBiosimilaireInformationsSection.ansmCreationPdf
            );
            pdfToDelete
              ?.filter((e) => e)
              .map(
                (e) => crudService.remove(`/blob-storage-azure`, { key: e }),
                (enableUpload = true)
              );
          }
        }
      }

      let formatedUrls = [];
      const formData = new FormData();
      if (data.biosimilaireInformationsSection) {
        if (
          data.biosimilaireInformationsSection.ansmCreationLink?.length &&
          data.biosimilaireInformationsSection.file === undefined &&
          (data.biosimilaireInformationsSection.ansmCreationPdf === "" ||
            data.biosimilaireInformationsSection.ansmCreationPdf === undefined)
        ) {
          const fileExtension =
            data.biosimilaireInformationsSection.ansmCreationLink.replace(
              /^.*\./,
              ""
            );
          formatedUrls.push({
            name: 1 + "_link." + fileExtension,
            path: data.biosimilaireInformationsSection.ansmCreationLink,
            index: "biosimilaireInformationsSection" + 1,
          });
          enableUpload = true;
        }
        if (data.biosimilaireInformationsSection.file !== undefined) {
          const myNewFile = new File(
            [data.biosimilaireInformationsSection.file],
            1 + "_" + data.biosimilaireInformationsSection.file.name
          );
          formData.append("biosimilaireInformationsSection" + 1, myNewFile);
          enableUpload = true;
        }
      }

      formData.append(
        "urlsList",
        JSON.stringify({
          data: formatedUrls,
        })
      );
      if (enableUpload) {
        crudService
          .post(`/blob-storage-azure/${agencyModel}/${fileId}`, formData)
          .then((s3Response) => {
            if (data.biosimilaireInformationsSection) {
              const oldEle = data.biosimilaireInformationsSection;
              delete oldEle.file;

              const pathData = s3Response.data.find(
                (e) => e.index === "biosimilaireInformationsSection" + 1
              );

              const formatedObject = {
                ...oldEle,
                ansmCreationPdf:
                  pathData?.key ??
                  data.biosimilaireInformationsSection.ansmCreationPdf,
              };
              data.biosimilaireInformationsSection = formatedObject;
            }
            crudService
              .put(`/${api}/${fileId}`, {
                ...data,
              })
              .then((res) => {
                dispatch({
                  type: SET_FILE_STATUS,
                  payload: { fileSlected: res.data },
                });
                dispatch({
                  type: EDIT_TYPE,
                  payload: res.data,
                });
              })
              .catch((err) => {
                console.log("error message", err.message);
              });
          });
      }
    }
  };
};

export const searchBiosimilaire = (limit = 5, offset = 0, where = null) => {
  let querParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    querParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/biosimilaires?${querParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
