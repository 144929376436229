import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { joTypes } from "../../../../Const/CommunObjects";
import { setFileStatus } from "../../../../Redux/Actions/FileSettingActions";

class JOHeader extends Component {
  render() {
    const { id, type } = this.props;
    return (
      <>
        <h1 className="settings__title title">
          {joTypes[type] + " ID : " + id}
        </h1>
        <div className="settings__actions actions-settings actions-settings-line">
          <div className="actions-settings__buttons actions-settings__buttons_line">
            <Link
              className="actions-settings__back button button-empty _icon-back"
              to={{
                pathname: `/admin/manager/bp_upload/list`,
                state: {
                  fromJo: true,
                },
              }}
              onClick={() =>
                this.props.setFileStatus({ fileSlected: {}, status: "get" })
              }
            >
              Back
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
  };
};

export default connect(mapStateToProps, {
  setFileStatus,
})(JOHeader);
