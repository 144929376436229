/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_INDICATION_JO_FILE_LIST,
  ADD_FILE_INDICATION_JO,
  EDIT_FILE_INDICATION_JO,
  DELETE_FILE_INDICATION_JO,
} from "../../Const/ActionTypes";

const initialState = {
  listIndicationJo: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INDICATION_JO_FILE_LIST: {
      return Object.assign({}, state, {
        listIndicationJo: action.payload,
      });
    }
    case EDIT_FILE_INDICATION_JO: {
      return Object.assign({}, state, {
        listIndicationJo: [
          action.payload,
          ...state.listIndicationJo.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }
    case ADD_FILE_INDICATION_JO: {
      return Object.assign({}, state, {
        listIndicationJo: [action.payload].concat(state.listIndicationJo),
      });
    }
    case DELETE_FILE_INDICATION_JO: {
      return Object.assign({}, state, {
        listIndicationJo: [
          ...state.listIndicationJo.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
