/* eslint import/no-anonymous-default-export: "off" */

import {
  SIGNIN_USER_SUCCESS,
  SIGN_OUT_USER,
} from "../../Const/ActionTypes";

const initialState = {
  authToken: localStorage.getItem("token"),
  role: "",
  authorizedFileList: [],
  userProfile: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        authToken: localStorage.getItem("token"),
        role: action.payload.role,
        authorizedFileList: action.payload.authorizedFileList,

      };
    }


    case SIGN_OUT_USER: {
      return {
        ...state,
        authToken: action.payload,
        userProfile: {},
      };
    }
    default:
      return state;
  }
}
