import React from "react";

import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ecoClinicalRoutes,
  pricingRoutes,
  refApplicatifRoutes,
} from "../../Routes";
import Logo from "../../images/logo.svg";
import UserPic from "../../images/user-avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../Redux/Actions/AuthActions";

const basePath = "/admin";

export default function NavBar(props) {
  const dispatch = useDispatch();

  const location = useLocation();

  const { role, userProfile } = useSelector(({ AuthReducer }) => AuthReducer);

  const handleClickLogout = () => {
    dispatch(userLogOut());
  };

  return (
    <header className="header">
      <div className="header__container _container">
        <Link to={basePath} className="header__logo">
          <img src={Logo} alt="Prismaaccess" />
        </Link>
        {props.authorizedFranceFileList?.length > 0 && (
          <div className="dropdown">
            <button className={`dropbtn menu__body menu__link`}>France</button>

            <div className="menu__item dropdown-content">
              {props.authorizedFranceFileList?.map(({ name, path }) => (
                <NavLink
                  key={name}
                  to={`${basePath}/${path}/list`}
                  className={(isActive) =>
                    isActive || location?.pathname.includes(path)
                      ? "dropdown-content-selected"
                      : ""
                  }
                >
                  {name}
                </NavLink>
              ))}
            </div>
          </div>
        )}

        {props.authorizedEuropeFileList?.length > 0 && (
          <div className="dropdown">
            <button className={`dropbtn menu__body menu__link`}>Europe</button>
            <div className="menu__item dropdown-content">
              {props.authorizedEuropeFileList?.map(({ name, path }) => (
                <NavLink
                  key={name}
                  to={`${basePath}/${path}/list`}
                  className={(isActive) =>
                    isActive || location?.pathname.includes(path)
                      ? "dropdown-content-selected"
                      : ""
                  }
                >
                  {name}
                </NavLink>
              ))}
            </div>
          </div>
        )}
        {props.authorizedAmericaFileList?.length > 0 && (
          <div className="dropdown">
            <button className={`dropbtn menu__body menu__link`}>America</button>
            <div className="menu__item dropdown-content">
              {props.authorizedAmericaFileList?.map(({ name, path }) => (
                <NavLink
                  key={name}
                  to={`${basePath}/${path}/list`}
                  className={(isActive) =>
                    isActive || location?.pathname.includes(path)
                      ? "dropdown-content-selected"
                      : ""
                  }
                >
                  {name}
                </NavLink>
              ))}
            </div>
          </div>
        )}

        {props.authorizedApacFileList?.length > 0 && (
          <div className="dropdown">
            <button className={`dropbtn menu__body menu__link`}>APAC</button>
            <div className="menu__item dropdown-content">
              {props.authorizedApacFileList?.map(({ name, path }) => (
                <NavLink
                  key={name}
                  to={`${basePath}/${path}/list`}
                  className={(isActive) =>
                    isActive || location?.pathname.includes(path)
                      ? "dropdown-content-selected"
                      : ""
                  }
                >
                  {name}
                </NavLink>
              ))}
            </div>
          </div>
        )}

        <div className="dropdown">
          <button className={`dropbtn menu__body menu__link`}>
            Eco & Clinical templates
          </button>
          <div className="menu__item dropdown-content">
            {ecoClinicalRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                className={(isActive) =>
                  isActive || location?.pathname.includes(path)
                    ? "dropdown-content-selected"
                    : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button className={`dropbtn menu__body menu__link`}>
            Pricing data
          </button>
          <div className="menu__item dropdown-content">
            {pricingRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                className={(isActive) =>
                  isActive || location?.pathname.includes(path)
                    ? "dropdown-content-selected"
                    : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="dropdown">
          <button className={`dropbtn menu__body menu__link`}>
            Référentiel applicatif
          </button>
          <div className="menu__item dropdown-content">
            {refApplicatifRoutes.map(({ name, path }) => (
              <NavLink
                key={name}
                to={`${basePath}/${path}/list`}
                className={(isActive) =>
                  isActive || location?.pathname.includes(path)
                    ? "dropdown-content-selected"
                    : ""
                }
              >
                {name}
              </NavLink>
            ))}
          </div>
        </div>

        <div className="header__actions actions-header">
          {role === "admin" && (
            <Link
              to={`${basePath}/user`}
              style={{ color: "white", textDecoration: "none" }}
              className="actions-header__button button"
            >
              Gestion des comptes
            </Link>
          )}

          <div className="actions-header__profile profile">
            <p className="profile__name">
              Welcome,
              <br />
              <span>
                {userProfile?.firstName + " " + userProfile?.lastName}{" "}
              </span>
            </p>
            <div className="dropdown">
              <div className="profile__image _icon-profile">
                <span>
                  <img src={UserPic} alt="profile" />
                </span>
              </div>

              <div className="menu__item dropdown-content dropdown-content-log-out">
                <span onClick={handleClickLogout}>Log out</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
