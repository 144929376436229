import React from "react";
import moment from "moment";

function TableCellValueUsers({ fileItem, columnName }) {
  const remainingDays =
    moment(fileItem.dateSessionEnd).diff(moment(), "days") + 1;

  return (
    <td>
      <div style={{ maxWidth: "100%" }} className="dashboard__item">
        {columnName.label === "ID"
          ? fileItem.id
          : columnName.label === "Nom"
          ? fileItem.lastName
          : columnName.label === "Prénom"
          ? fileItem.firstName
          : columnName.label === "Rôle"
          ? fileItem.role
          : columnName.label === "Statut"
          ? fileItem.status
          : columnName.label === "Société"
          ? fileItem.society
          : columnName.label === "Email"
          ? fileItem.email
          : columnName.label === "Pays"
          ? fileItem.country
          : columnName.label === "Jours restants"
          ? remainingDays > 0
            ? remainingDays
            : 0
          : ""}
      </div>
    </td>
  );
}

export default TableCellValueUsers;
