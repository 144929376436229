import React, { Fragment } from "react";

import Styles from "./Skeleton.module.css";

function SkeletonFileItem() {
  const randomArray = Array(3).fill();

  return (
    <Fragment>
      {randomArray.map((eleemnt, index) => (
        <div className="spollers__item" key={index}>
          <div className={Styles["skeleton-container"]}>
            <div
              className={`${Styles["card__section-item"]} ${Styles["is-loading"]} `}
            >
              <div className={Styles["content__first-line"]}>
                <p className={Styles["content-p-tags"]}></p>
              </div>

              <div className={Styles["content"]}>
                <p className={Styles["content-p-tags"]}></p>

                <p className={Styles["content-p-tags"]}></p>

                <p className={Styles["content-p-tags"]}></p>

                <p className={Styles["content-p-tags"]}></p>
              </div>

              <div className={Styles["content__last-line"]}>
                <p className={Styles["content-p-tags"]}></p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}

export default SkeletonFileItem;
