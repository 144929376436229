/* eslint import/no-anonymous-default-export: "off" */

import { GET_EMA_FILE_LIST, ADD_FILE_EMA, EDIT_FILE_EMA, DELETE_FILE_EMA } from "../../Const/ActionTypes";

const initialState = {
  listEma: [],
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMA_FILE_LIST: {
      return Object.assign({}, state, {
        listEma: action.payload,
      });
    }
    case EDIT_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [action.payload, ...state.listEma.filter((element) => element.id !== action.payload.id)],
       
      });
    }
    case ADD_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [action.payload].concat(state.listEma),
       });
    }
    case DELETE_FILE_EMA: {
      return Object.assign({}, state, {
        listEma: [...state.listEma.filter((element) => element.id !== action.payload)],
      });
    }
    default:
      return state;
  }
}
