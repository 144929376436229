import React, { Component } from "react";
import { connect } from "react-redux";
import CommunAlerte from "../CommunComponents/CommunAlerte";
import TablePagination from "../CommunComponents/TablePagination";
import {
  setFileStatus,
  displayNotFoundPage,
} from "../../Redux/Actions/FileSettingActions";
import UserAccountHeader from "./UserAccountHeader";
import UsersFileListItem from "./UsersFileListItem";
import AddUser from "./AddUser";
import UsersTableHead from "./UsersTableHead";
import { withRouter } from "react-router-dom";
import SkeletonSectionItem from "../Loading/Skeletons/SkeletonSectionItem";


import SkeletonFileItem from "../Loading/Skeletons/SkeletonFileItem";
import {
  getUserById,
  clearSelectedUser,
} from "../../Redux/Actions/UserActions";

class UsersFileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToEdit: undefined,
    };
  }

  handleChangeEdit = (row) => {
    this.setState({
      userToEdit: row,
    });
  };

  redirect = () => {
    const fileId = this.props.match.params?.fileId;

    this.props.history.push(`/admin/${this.props.fileSetting.filePath}`);

    this.props.displayNotFoundPage(fileId);
  };

  componentDidUpdate(prevProps) {
    if (this.props.fromTask && prevProps.userToEdit !== this.props.userToEdit) {
      const userToEdit = this.props.userToEdit;

      if (userToEdit) {
        this.setState({ userToEdit });
      }
    }
  }

  componentDidMount() {
    if (this.props.fromTask) {
      const userId = this.props.match.params?.fileId;

      this.props.getUserById(userId, this.redirect);
    }
  }

  render() {
    const { listItems: rows, headerItems, filePath, type } = this.props;

    const { loading } = this.props.loading;

    if (!this.state.userToEdit?.id && type === "edit") {
      return (
        <div className="spollers__item">
          <div className="settings__container _container">
            <div className="settings__wrapper">
              <div className="dashboard__actions actions-dashboard">
                <div className="actions-dashboard__left">
                  <h1 className="dashboard__title title">
                    Modification d'un utilisateur
                  </h1>
                </div>
              </div>
              <SkeletonFileItem />
            </div>
          </div>
        </div>
      );
    }

    return this.props.activeAction !== false &&
      type === "edit" &&
      this.state.userToEdit?.id ? (
      <AddUser
        type="edit"
        handleOpenSection={this.props.handleOpenSection}
        userToEdit={this.state.userToEdit}
        title="Modification"
        handleOpenForm={this.props.handleOpenForm}
        handleCloseForm={this.props.handleCloseForm}
        filePath={filePath}
        loadingFindById={false}
      />
    ) : (
      <section className="dashboard">
        <div className="dashboard__container _container">
          <div className="dashboard__wrapper">
            <CommunAlerte />
            <UserAccountHeader filePath={filePath} />
            <div className="dashboard__table-wrapper">
              {loading ? (
                <SkeletonSectionItem />
              ) : (
                <table className="dashboard__table">
                  <UsersTableHead />
                  <tbody className="dashboard__body">
                    {rows.map((row, index) => (
                      <UsersFileListItem
                        row={row}
                        key={index}
                        headerItems={headerItems}
                        handleChangeEdit={this.handleChangeEdit}
                        handleOpenDelete={this.props.handleOpenDelete}
                        filePath={filePath}
                      />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <TablePagination rowsLength={rows?.length ?? 0} fromUser />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
    loading: state.UIReducer,
    userToEdit: state.UserReducer.userToEdit,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setFileStatus,
    displayNotFoundPage,
    getUserById,
    clearSelectedUser,
  })(UsersFileList)
);
