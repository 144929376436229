/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_ZINL_FILE_LIST,
  ADD_FILE_ZINL,
  EDIT_FILE_ZINL,
  DELETE_FILE_ZINL,
} from "../../Const/ActionTypes";

const initialState = {
  listZinl: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ZINL_FILE_LIST: {
      return Object.assign({}, state, {
        listZinl: action.payload,
      });
    }
    case EDIT_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [
          action.payload,
          ...state.listZinl.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }
    case ADD_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [action.payload].concat(state.listZinl),
      });
    }
    case DELETE_FILE_ZINL: {
      return Object.assign({}, state, {
        listZinl: [
          ...state.listZinl.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
