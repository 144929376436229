import {
  GET_INDICATION_JO_FILE_LIST,
  ADD_FILE_INDICATION_JO,
  EDIT_FILE_INDICATION_JO,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_DUPLICATION_ID,
    GET_SEARCHED_FILES_BY_COLUMN,
} from "../../Const/ActionTypes";

import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getIndicationJoFiles = () => {
  return (dispatch) => {
    crudService
      .get(`/indication-jos`)
      .then((res) => {
        dispatch({
          type: GET_INDICATION_JO_FILE_LIST,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalIndicationJoSubmit = (data, duplication = false) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/indication-jos`, data)
      .then((res) => {
        dispatch({
          type: ADD_FILE_INDICATION_JO,
          payload: res.data,
        });

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche Indications JO est dupliquée avec succès" +
              " ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche Indications JO est ajoutée avec succès" +
              " ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalIndicationJoEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/indication-jos/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_INDICATION_JO,
          payload: res.data,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,

          payload:
            sectionName === undefined
              ? "La fiche Indications JO ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche Indications JO"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const searchIndicationJo = (limit = 5, offset = 0, where = null) => {
  let queryParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    queryParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/indication-jos?${queryParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
