/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_PBAC_FILE_LIST,
  ADD_FILE_PBAC,
  EDIT_FILE_PBAC,
  DELETE_FILE_PBAC,
} from "../../Const/ActionTypes";

const initialState = {
  listPbac: [],
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PBAC_FILE_LIST: {
      return Object.assign({}, state, {
        listPbac: action.payload,
      });
    }
    case EDIT_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [
          action.payload,
          ...state.listPbac.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
       });
    }
    case ADD_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [action.payload].concat(state.listPbac),
       });
    }
    case DELETE_FILE_PBAC: {
      return Object.assign({}, state, {
        listPbac: [
          ...state.listPbac.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
