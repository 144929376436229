/* eslint import/no-anonymous-default-export: "off" */

import {GET_TASK_LIST, GET_COMMENTS_BY_FILE, DISMISS_TASK} from '../../Const/ActionTypes';
const initialState = {
  commentListRecived: [],
  commentListSended: [],
  fileComments: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TASK_LIST: {
      return Object.assign({}, state, {
        commentListRecived: action.payload.commentListRecived.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }),
        commentListSended: action.payload.commentListSended.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }),
      });
    }
    case DISMISS_TASK: {
      return Object.assign({}, state, {
        commentListRecived: [
          ...state.commentListRecived.filter(
            (element) => !action.payload.includes(element.commentRecipientId)
          ),
        ],
      });
    }
    case GET_COMMENTS_BY_FILE: {
      return Object.assign({}, state, {
        fileComments: action.payload,
      });
    }

    default:
      return state;
  }
}
