/* eslint import/no-anonymous-default-export: "off" */

import {
  ADD_FILE_CADTH,
  DELETE_FILE_CADTH,
  EDIT_FILE_CADTH,
  GET_CADTH_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listCadth: [],
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CADTH_FILE_LIST: {
      return Object.assign({}, state, {
        listCadth: action.payload,
      });
    }

    case ADD_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [action.payload].concat(state.listCadth),
       });
    }
    case EDIT_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [
          action.payload,
          ...state.listCadth.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
       });
    }
    case DELETE_FILE_CADTH: {
      return Object.assign({}, state, {
        listCadth: [
          ...state.listCadth.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
