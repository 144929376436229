/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_TLV_FILE_LIST,
  ADD_FILE_TLV,
  EDIT_FILE_TLV,
  DELETE_FILE_TLV,
} from "../../Const/ActionTypes";

const initialState = {
  listTlv: [],
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TLV_FILE_LIST: {
      return Object.assign({}, state, {
        listTlv: action.payload,
      });
    }
    case EDIT_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: state.listTlv.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        
       });
    }
    case ADD_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: [action.payload].concat(state.listTlv),
       });
    }

    case DELETE_FILE_TLV: {
      return Object.assign({}, state, {
        listTlv: [
          ...state.listTlv.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
