import React, { Component } from "react";
import { connect } from "react-redux";
import { setFileStatus } from "../../Redux/Actions/FileSettingActions";
import { deleteFile, getFiles } from "../../Redux/Actions/CommunFilesAction";

class ConfirmDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteCallback = async () => {
    const {
      page,
      rowsPerPage,
      getAction,
      fileApi,
      attribut,
      order: orderReducer,
      searchFilterArgs,
      searchAttributes,
    } = this.props.fileSetting;

    const getRowsPerPage = (arg = 5) => {
      return searchFilterArgs ? searchAttributes.rowsPerPageSearch : arg;
    };

    let filterArg = null;

    if (searchFilterArgs?.filterArg) {
      filterArg = {
        ...searchFilterArgs.filterArg,
      };
    }

    if (attribut) {
      const order = orderReducer === "ASC" ? "DESC" : "ASC";
      const sortArgs = { order, attribut };

      filterArg = {
        ...filterArg,
        sort: {
          ...sortArgs,
        },
      };
    }

    await this.props.getFiles(
      fileApi,
      getAction,
      getRowsPerPage(rowsPerPage),
      page * getRowsPerPage(rowsPerPage),
      filterArg
    );

    this.props.setFileStatus({
      fileSlected: {},
      status: searchFilterArgs ? "search" : "get",
    });
    window.scrollTo(0, 0);
  };

  confirmDelete = () => {
    const {
      fileApi,

      deleteAction,
      currentFile,
      fileSlected,
    } = this.props.fileSetting;

    this.props.deleteFile(
      fileApi,
      deleteAction,
      currentFile,
      fileSlected,
      this.deleteCallback
    );
  };

  render() {
    return (
      <div
        className={`action-popup action-popup-edit ${
          this.props.fileSetting.status === "delete" ? "_active" : ""
        }`}
      >
        <div className="action-popup__wrapper">
          <div className="action-popup__content">
            <p className="action-popup__title">Êtes-vous sûr?</p>
            <p className="action-popup__text">
              {"Voulez-vous supprimer cette fiche " +
                this.props.fileSetting.currentFile +
                " ( ID : " +
                this.props.fileSetting.fileSlected?.id +
                " ) "}
            </p>
            <div className="action-popup__buttons">
              <button
                className="action-popup__button action-popup__button_continue button"
                onClick={() => this.confirmDelete()}
              >
                Continuer
              </button>
              <button
                className="action-popup__button action-popup__button_close button button-empty"
                onClick={() =>
                  this.props.setFileStatus({ fileSlected: {}, status: "get" })
                }
              >
                Quitter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fileSetting: state.FileSettingReducer,
  };
};

export default connect(mapStateToProps, {
  setFileStatus,
  deleteFile,
  getFiles,
})(ConfirmDelete);
