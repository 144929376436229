/* eslint-disable  */

import _ from "lodash";
import { crudService } from "../../Config/CrudService";
import { SET_FILE_STATUS } from "../../Const/ActionTypes";
import { getExtension } from "../../Const/ValidationFunctions";

/*** get pdf Document */
export const getPDFFiles = (
  agencyModel,
  data,
  fileId,
  api,
  EDIT_TYPE,
  sectionName
) => {
  return (dispatch) => {
    if (!(data.id && data.id !== fileId)) {
      let enableUpload = false;
      /** if edit function  */
      if (data.id !== undefined) {
        sectionName.map((section) => {
          if (data[section.name] && data[section.listOld]) {
            // check if data[section.name] is an array
            if (
              typeof data[section.name] !== "object" ||
              Array.isArray(data[section.name])
            ) {
              let pdfToDelete = _.differenceBy(
                data[section.listOld],
                data[section.name],
                section.path
              );

              if (section.name === "keyDocumentsSection") {
                pdfToDelete
                  .filter(
                    (e) => e.fromSection === section.name && e[section.path]
                  )
                  .map((e) => {
                    crudService.remove(`/blob-storage-azure`, {
                      key: e[section.path],
                    });
                    enableUpload = true;
                  });
              } else {
                pdfToDelete
                  ?.filter((e) => e[section.path])
                  .map((e) => {
                    crudService.remove(
                      `/blob-storage-azure`,

                      { key: e[section.path] }
                    );
                    enableUpload = true;
                  });
              }
            } else {
              // if data[section.name] is an object

              let pdfToDelete = [];

              if (
                data[section.name][section.path] !==
                data[section.listOld][section.path]
              ) {
                pdfToDelete.push(data[section.listOld][section.path]);
                pdfToDelete
                  ?.filter((e) => e)
                  .map((e) => {
                    crudService.remove(`/blob-storage-azure`, { key: e });
                    enableUpload = true;
                  });
              }
            }
          }
        });
      }

      let formatedUrls = [];
      const formData = new FormData();
      sectionName.map((section) => {
        if (data[section.name]) {
          // check if data[section.name] is an array
          if (
            typeof data[section.name] !== "object" ||
            Array.isArray(data[section.name])
          ) {
            data[section.name].map((element, index) => {
              if (
                element[section.link]?.length &&
                element.file === undefined &&
                element[section.path] === ""
              ) {
                const fileExtension = getExtension(element[section.link]);

                // element[section.link].replace(
                //   /^.*\./,
                //   ""
                // );

                formatedUrls.push({
                  name: index + 1 + "_link." + fileExtension,
                  path: element[section.link],
                  index: section.name + index,
                });
                enableUpload = true;
              }

              if (element.file !== undefined) {
                const myNewFile = new File(
                  [element.file],
                  index + 1 + "_" + element.file.name
                );

                formData.append(section.name + index, myNewFile);
                enableUpload = true;
              }

              return null;
            });
          } else {
            if (
              data[section.name][section.link]?.length &&
              data[section.name]?.file === undefined &&
              data[section.name]?.[section.path] === ""
            ) {
              const fileExtension = getExtension(
                data[section.name][section.link]
              );

              formatedUrls.push({
                name: 1 + "_link." + fileExtension,
                path: data[section.name][section.link],
                index: section.name + 1,
              });
              enableUpload = true;
            }
            if (data[section.name]?.file !== undefined) {
              const myNewFile = new File(
                [data[section.name].file],
                1 + "_" + data[section.name].file.name
              );
              formData.append(section.name + 1, myNewFile);
              enableUpload = true;
            }
            return null;
          }
        }
      });

      formData.append(
        "urlsList",
        JSON.stringify({
          data: formatedUrls,
        })
      );

      if (enableUpload) {
        crudService
          .post(`/blob-storage-azure/${agencyModel}/${fileId}`, formData)
          .then((s3Response) => {
            let newkeyDocumentsSection = JSON.parse(
              JSON.stringify(data.keyDocumentsSection)
            )
              .filter(
                (e) => e.fromSection === "keyDocumentsSection" || !e.fromSection
              )
              .map((oldEle, index) => {
                delete oldEle.file;
                let pathData = s3Response.data.find(
                  (e) => e.index === "keyDocumentsSection" + oldEle.index
                );
                let formatedObject = {
                  ...oldEle,
                  index: index,
                  path: pathData?.key ?? oldEle.path,
                };
                return formatedObject;
              });

            let lastIndex = newkeyDocumentsSection.length;

            sectionName.map((section) => {
              // check if type of section is an array
              if (data[section.name]) {
                if (
                  typeof data[section.name] !== "object" ||
                  Array.isArray(data[section.name])
                ) {
                  data[section.name] = data[section.name].map(
                    (oldEle, index) => {
                      delete oldEle.file;
                      let pathData = s3Response.data.find(
                        (e) => e.index === section.name + index
                      );

                      let formatedObject = {
                        ...oldEle,
                        [section.path]: pathData?.key ?? oldEle[section.path],
                      };
                      if (
                        section.name !== "keyDocumentsSection" &&
                        section.name !== "atuSection" &&
                        (formatedObject[section.path] ||
                          formatedObject[section.link]) &&
                        newkeyDocumentsSection
                      ) {
                        newkeyDocumentsSection.unshift({
                          index: lastIndex,
                          title:
                            formatedObject?.[section.spec] ?? section.title,
                          label:
                            formatedObject?.[section.spec] ?? section.title,
                          path: formatedObject[section.path],
                          type: "",
                          link: formatedObject[section.link],
                          date: null,
                          fromSection: section.name,
                        });
                      }

                      lastIndex += 1;
                      return formatedObject;
                    }
                  );
                } else {
                  const sectionObject = section.name;
                  const sectionTilte = section.title;
                  const oldEle = data[sectionObject];

                  delete oldEle?.file;

                  const pathData = s3Response.data.find(
                    (e) => e.index === sectionObject + 1
                  );

                  const formatedObject = {
                    ...oldEle,
                    [section.path]:
                      pathData?.key ?? data[sectionObject][section.path],
                  };

                  if (
                    sectionObject !== "keyDocumentsSection" &&
                    (formatedObject[section.path] ||
                      formatedObject[section.link]) &&
                    newkeyDocumentsSection
                  ) {
                    newkeyDocumentsSection.unshift({
                      index: lastIndex,
                      title: sectionTilte,
                      label: sectionTilte,
                      path: formatedObject[section.path],
                      type: section.type ? section.type : "",
                      link: formatedObject[section.link],
                      date: null,
                      language: section.language ? section.language : "",
                      fromSection: sectionObject,
                    });
                  }

                  lastIndex += 1;
                  data[sectionObject] = formatedObject;
                }
              }
            });

            crudService
              .put(`/${api}/${fileId}`, {
                ...data,
                keyDocumentsSection: newkeyDocumentsSection,
              })
              .then((res) => {
                dispatch({
                  type: SET_FILE_STATUS,
                  payload: { fileSlected: res.data },
                });
                dispatch({
                  type: EDIT_TYPE,
                  payload: res.data,
                });
              })
              .catch((err) => {
                console.log("error message", err.message);
              });
          });
      }
    }
  };
};
