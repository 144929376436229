/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_APAC_FILE_LIST,
  ADD_FILE_APAC,
  EDIT_FILE_APAC,
  DELETE_FILE_APAC,
} from "../../Const/ActionTypes";

const initialState = {
  listAPAC: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APAC_FILE_LIST: {
      return Object.assign({}, state, {
        listAPAC: action.payload,
      });
    }
    case EDIT_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: state.listAPAC.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
      });
    }
    case ADD_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: [action.payload].concat(state.listAPAC),
      });
    }

    case DELETE_FILE_APAC: {
      return Object.assign({}, state, {
        listAPAC: [
          ...state.listAPAC.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
