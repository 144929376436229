/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_CDF_FILE_LIST,
  ADD_FILE_CDF,
  EDIT_FILE_CDF,
  DELETE_FILE_CDF,
} from "../../Const/ActionTypes";

const initialState = {
  listCDF: [],
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CDF_FILE_LIST: {
      return Object.assign({}, state, {
        listCDF: action.payload,
      });
    }
    case EDIT_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [
          action.payload,
          ...state.listCDF.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
       });
    }
    case ADD_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [action.payload].concat(state.listCDF),
       });
    }

    case DELETE_FILE_CDF: {
      return Object.assign({}, state, {
        listCDF: [
          ...state.listCDF.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
