import {
  GET_LIST_SPECIALITY,
  ADD_SPECIALITY,
  EDIT_SPECIALITY,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SHOW_ERROR_MESSAGE_LIST,
  DELETE_SPECIALITY,
} from "../../Const/ActionTypes";

import _ from "lodash";
import { setLoading, unsetLoading } from "./UIActions";
import { decrementCount, incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const editSpeciality = (data) => {
  return (dispatch) => {
    crudService
      .patch(`/drug-specialities/` + data.id, data)
      .then((res) => {
        dispatch({
          type: EDIT_SPECIALITY,
          payload: { ...res.data, value: res.data?.id, label: res.data?.name },
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La modification  est effectuée avec succès",
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message === "Internal Server Error"
            ? "name duplicated"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      });
  };
};

export const addSpeciality = (data) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/drug-specialities`, data)
      .then((res) => {
        if (res.data.success.length > 0) {
          dispatch({
            type: ADD_SPECIALITY,
            payload:
              res.data.success?.map((element) => ({
                ...element,
                value: element.id,
                label: element.name,
              })) ?? [],
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload:
              "The creation of " +
              res.data.success.length +
              " element/s is successful",
          });

          dispatch(incrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        }
        if (res.data.failed.length > 0) {
          dispatch({
            type: SHOW_ERROR_MESSAGE_LIST,
            payload: _.uniq(res.data.failed),
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getSpecialityList = () => {
  return async (dispatch) => {
    let filter = JSON.stringify({
      fields: {
        id: true,
        name: true,
      },
      order: ["id DESC"],
    });

    await crudService
      .get(`/drug-specialities?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_LIST_SPECIALITY,
          payload:
            res.data?.map((element) => ({
              ...element,
              value: element.id,
              label: element.name,
            })) ?? [],
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const deleteSpeciality = (id) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .remove(`/drug-specialities/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SPECIALITY,
          payload: id,
        });

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });

        dispatch(decrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
        window.scrollTo(0, 0);
      });
  };
};
