import React, { Component } from "react";
import flatpickr from "flatpickr";
import moment from "moment";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.datePicker = React.createRef();
  }

  onChange = (selectedDates, dateStr, instance) => {
    let [year, month, day] = dateStr.split("-");

    const date = new Date();
    date.setFullYear(year, +month - 1, day);

    this.props.onChange(dateStr ? moment(date).format("YYYY-MM-DD") : null);
  };

  componentDidMount() {
    flatpickr(this.datePicker.current, {
      onChange: this.onChange,
      dateFormat: "YYYY-MM-DD",
      altFormat: "DD-MM-YYYY",

      parseDate: (datestr, format) => {
        return moment(datestr, format, true).toDate();
      },

      formatDate: (date, format, locale) => {
        return moment(date).format("YYYY-MM-DD");
      },
    });
  }

  render() {
    let { onChange, value, disabled, width, customStyle } = this.props;
    customStyle = customStyle ?? {};
    return (
      <div style={{ display: "flex" }}>
        <input
          disabled={this.props.disabled}
          value={
            this.props.value
              ? moment(this.props.value).format("YYYY-MM-DD")
              : ""
          }
          id="datepicker"
          className="flatpickr flatpickr-input active select select-calendar active"
          type="date"
          ref={this.datePicker}
          placeholder="Select Date.."
          readOnly="readonly"
          style={{ width: width ?? "fit-content", ...customStyle }}
        />

        {onChange && value && !disabled && (
          <button
            onClick={() => this.props.onChange(null)}
            title="Supprimer la date"
            style={{ marginLeft: "0.2rem", color: "#b8b8b8" }}
          >
            <i className="far fa-times-circle"></i>
          </button>
        )}
      </div>
    );
  }
}

export default DatePicker;
