import { crudService } from "../../Config/CrudService";
import { GET_SEARCHED_FILES_BY_COLUMN } from "../../Const/ActionTypes";

export const searchPricing = (
  ednpoint,
  limit = 5,
  offset = 0,
  where = null
) => {
  let queryParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    queryParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/${ednpoint}?${queryParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};
