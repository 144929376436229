import {
  GET_ECONOMIC_EVALUATION_FILE_LIST,
  ADD_FILE_ECONOMIC_EVALUATION,
  EDIT_FILE_ECONOMIC_EVALUATION,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION,
  SET_DUPLICATION_ID,
  GET_SEARCHED_FILES_BY_COLUMN,
} from "../../Const/ActionTypes";

import { getPDFFiles } from "./KeyDocumentAction";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getEconomicEvaluationFiles = (limit, offset) => {
  let pagination = "?";
  if (limit) pagination = pagination + "limit=" + limit;
  if (offset) pagination = pagination + "&offset=" + offset;

  return (dispatch) => {
    crudService
      .get(`/economic-evaluations${pagination}`)
      .then((res) => {
        dispatch({
          type: GET_ECONOMIC_EVALUATION_FILE_LIST,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalEconomicEvaluationSubmit = (data, duplication = false) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/economic-evaluations`, data)
      .then((res) => {
        dispatch({
          type: ADD_FILE_ECONOMIC_EVALUATION,
          payload: res.data,
        });

        dispatch(
          getPDFFiles(
            "Economic Evaluation",
            data,
            res.data.id,
            "economic-evaluations",
            EDIT_FILE_ECONOMIC_EVALUATION,
            [
              {
                name: "keyDocumentsSection",
                path: "path",
                link: "link",
                listOld: "oldKeyDocumentList",
              },
            ]
          )
        );

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche Economic Evaluation est dupliquée avec succès  ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche Economic Evaluation est ajoutée avec succès  ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.log("-------------err----", err);
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });

        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalEconomicEvaluationEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/economic-evaluations/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_ECONOMIC_EVALUATION,
          payload: res.data,
        });

        dispatch(
          getPDFFiles(
            "Economic Evaluation",
            data,
            res.data.id,
            "economic-evaluations",
            EDIT_FILE_ECONOMIC_EVALUATION,
            [
              {
                name: "keyDocumentsSection",
                path: "path",
                link: "link",
                listOld: "oldKeyDocumentList",
              },
            ]
          )
        );

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload:
            sectionName === undefined
              ? "La fiche Economic Evaluation ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.log(err, "**tlv error");
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche Economic Evaluation"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const searchEconomicEvaluation = (
  limit = 5,
  offset = 0,
  where = null
) => {
  let queryParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    queryParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/economic-evaluations?${queryParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const setTypeOfEcoAnalysis = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION,
      payload,
    });
  };
};
