import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../Redux/Actions/AuthActions";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import AdminRoute from "./AdminRoute";
import Callback from "./Callback";
import NotFound from "./CommunComponents/NotFound";
import SSO from "./SSO";
import Spinner from "./Loading/Spinner/Spinner";
import SpinnerLogOut from "./Loading/Spinner/SpinnerLogOut";
import { datadogRum } from "@datadog/browser-rum";
import ErrorConnecting from "./Error/ErrorConnecting";
// import { jwtDecode } from "jwt-decode";

function MainApp() {
  const dispatch = useDispatch();

  const authToken = useSelector((state) => state.AuthReducer.authToken);
  const userProfile = useSelector((state) => state.AuthReducer.userProfile);
  const { loadingUser, loadingLogOut } = useSelector(
    (state) => state.UIReducer
  );

  if (process.env.REACT_APP_NODE_ENV !== "local" && userProfile?.id) {
    // set metadata for datadog
    datadogRum.setUser({
      id: userProfile.id.toString(),
      name: `${userProfile.firstName} ${userProfile.lastName}`,
      email: userProfile.email,
      role: userProfile.role,
    });
  }

  useEffect(() => {
    if (window.location.pathname !== "/" && authToken && !userProfile?.id) {
      // const decoded = jwtDecode(authToken);

      // setTimeout(() => {
      //   dispatch(refreshUserToken());
      // }, (decoded.exp - 300) * 1000 - new Date().getTime());

      dispatch(getUserInfo());
    }
  }, [dispatch, authToken, userProfile]);

  if (loadingUser) {
    return <Spinner />;
  }

  if (loadingLogOut) {
    return <SpinnerLogOut />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SSO} />

        <Route path="/callback" component={Callback} />

        <Route path="/error" component={ErrorConnecting} />

        {userProfile?.id && (
          <Route>
            <AdminRoute />
          </Route>
        )}

        <Route
          path="*"
          exact
          component={userProfile?.id ? NotFound : ErrorConnecting}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default MainApp;
