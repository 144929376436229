import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFileStatus } from "../../Redux/Actions/FileSettingActions";

function TablePagination({ typeChecked, fromUser }) {
  const { page, rowsPerPage, maxRows, maxRowReceived, maxRowSended } =
    useSelector(({ FileSettingReducer }) => FileSettingReducer);

  const { loading, loadingSearch } = useSelector(({ UIReducer }) => UIReducer);

  const [maxPages, setMaxPages] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (typeChecked === "receive") {
      setMaxPages(Math.ceil(maxRowReceived / rowsPerPage));
    } else if (typeChecked === "send") {
      setMaxPages(Math.ceil(maxRowSended / rowsPerPage));
    } else {
      setMaxPages(Math.ceil(maxRows / rowsPerPage));
    }
  }, [typeChecked, maxRowReceived, rowsPerPage, maxRowSended, maxRows]);

  let maxPagesList = [];

  for (let index = 0; index < maxPages; index++) {
    maxPagesList.push(index);
  }

  let indexPageSelected = maxPagesList.indexOf(page);

  if (loading || loadingSearch) {
    return <Fragment></Fragment>;
  }
  return (
    <div className="dashboard__paggination paggination-dashboard">
      {typeChecked === "receive" && (
        <div>
          <p className="paggination-dashboard__text">
            Total : <span style={{ color: "#ff763f" }}>{maxRowReceived}</span>{" "}
            fiches
          </p>
        </div>
      )}
      {typeChecked === "send" && (
        <div>
          <p className="paggination-dashboard__text">
            Total : <span style={{ color: "#ff763f" }}>{maxRowSended}</span>{" "}
            fiches
          </p>
        </div>
      )}

      {!typeChecked && (
        <div className="dashboard__paggination paggination-dashboard">
          <div>
            <p className="paggination-dashboard__text">
              Total : <span style={{ color: "#ff763f" }}>{maxRows}</span>{" "}
              {fromUser ? "utilisateurs" : "fiches"}
            </p>
          </div>
        </div>
      )}

      {(!!maxRows || !!maxRowReceived || !!maxRowSended) && (
        <span className="dashboard__paggination-right">
          <div className="paggination-dashboard__count">
            <p className="paggination-dashboard__text">Afficher par page</p>
            <select
              className="paggination-dashboard__select"
              value={rowsPerPage}
              onChange={(e) =>
                dispatch(
                  setFileStatus({
                    rowsPerPage: +e.target.value,
                    page: 0,
                  })
                )
              }
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>

          <ul className="paggination-dashboard__list">
            <li
              className="paggination-dashboard__item _icon-paggin"
              onClick={() => {
                if (page !== 0) {
                  dispatch(setFileStatus({ page: page - 1 }));
                }
              }}
            />
            {maxPagesList
              .filter(
                (e) => e <= indexPageSelected + 3 && e >= indexPageSelected - 3
              )
              .map((pageEle) => (
                <li
                  key={pageEle}
                  onClick={() => dispatch(setFileStatus({ page: pageEle }))}
                  className={`paggination-dashboard__item   ${
                    pageEle === page ? "_active" : ""
                  }`}
                >
                  {pageEle + 1}
                </li>
              ))}

            <li
              className="paggination-dashboard__item _icon-paggin"
              onClick={() => {
                if (page !== maxPages - 1) {
                  dispatch(
                    setFileStatus({
                      page: page + 1,
                    })
                  );
                }
              }}
            />
          </ul>
        </span>
      )}
    </div>
  );
}

export default TablePagination;
