/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_SPAIN_FILE_LIST,
  ADD_FILE_SPAIN,
  EDIT_FILE_SPAIN,
  DELETE_FILE_SPAIN,
} from "../../Const/ActionTypes";

const initialState = {
  listSpain: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SPAIN_FILE_LIST: {
      return Object.assign({}, state, {
        listSpain: action.payload,
      });
    }
    case EDIT_FILE_SPAIN: {
      return Object.assign({}, state, {
        listSpain: [
          action.payload,
          ...state.listSpain.filter(
            (element) => element.id !== action.payload.id
          ),
        ],
      });
    }
    case ADD_FILE_SPAIN: {
      return Object.assign({}, state, {
        listSpain: [action.payload].concat(state.listSpain),
      });
    }
    case DELETE_FILE_SPAIN: {
      return Object.assign({}, state, {
        listSpain: [
          ...state.listSpain.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
