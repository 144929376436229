import { crudService } from "../../Config/CrudService";
import {
  COUNT_SEARCH,
  GET_SEARCHED_FILES_BY_COLUMN,
  LOADING_SEARCH_FILE,
} from "../../Const/ActionTypes";
import { searchAnsm } from "./ANSMActions";
import { searchBiosimilaire } from "./BiosimilaireAction";
import { searchBoitePresentation } from "./BoitePresentationActions";
import { searchClinicalTrials } from "./ClinicalTrialsActions";
import {
  displayErroSnackbar,
  serachFileByColumnRef,
} from "./CommunFilesAction";
import { searchEconomicEvaluation } from "./EconomicEvaluationActions";
import { setFileStatus, storeSearchFilterArgs } from "./FileSettingActions";
import { searchIndicationJo } from "./IndicationJoActions";
import { searchPricing } from "./PricingCommun";
import { getASMRList, getSMRList } from "./SMRActions";
import { searchTfr } from "./TFRActions";

export const specificFilesCount =
  (endpoint, whereArgs, fromEcoEval = false) =>
  async (dispatch) => {
    const where = JSON.stringify(whereArgs);
    const queryParam = fromEcoEval ? "filter" : "where";

    dispatch({
      type: COUNT_SEARCH,
      payload: 0,
    });

    try {
      const { data } = await crudService.get(
        `/${endpoint}/count?${queryParam}=${where}`
      );

      dispatch({
        type: COUNT_SEARCH,
        payload: data?.count ?? 0,
      });

      if (!data?.count) {
        dispatch(
          displayErroSnackbar("No records with the given criteria were found.")
        );
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: [] });
        dispatch(setFileStatus({ status: "get" }));
        dispatch(storeSearchFilterArgs(null));
      }

      return data?.count ?? 0;
    } catch (err) {
      console.log("error message", err.message);

      dispatch(displayErroSnackbar(err));
    }
  };

export const specificFilesRouter =
  (endpoint, whereArgs, limit, offset, fromPagination = false) =>
  async (dispatch) => {
    let count = 0;

    const action = {
      "service-medical-rendus": getSMRList(limit, offset, whereArgs, true),
      "amelioration-service-medical-rendus": getASMRList(
        limit,
        offset,
        whereArgs,
        true
      ),

      "boite-presentations": searchBoitePresentation(
        limit,
        offset,
        whereArgs,
        true
      ),
      ansms: searchAnsm(limit, offset, whereArgs, true),
      biosimilaires: searchBiosimilaire(limit, offset, whereArgs, true),
      tfrs: searchTfr(limit, offset, whereArgs, true),
      "indication-jos": searchIndicationJo(limit, offset, whereArgs, true),
      "drug-categories": serachFileByColumnRef(
        endpoint,
        whereArgs,
        limit,
        offset,
        true
      ),
      indexations: serachFileByColumnRef(
        endpoint,
        whereArgs,
        limit,
        offset,
        true
      ),

      "chemical-ingredients": serachFileByColumnRef(
        endpoint,
        whereArgs,
        limit,
        offset,
        true
      ),

      "ncts-infos": searchClinicalTrials(limit, offset, whereArgs, true),
      "economic-evaluations": searchEconomicEvaluation(
        limit,
        offset,
        whereArgs
      ),

      pricing: searchPricing(endpoint, limit, offset, whereArgs),
    };

    if (!action[endpoint] && !endpoint.includes("prix")) {
      dispatch(displayErroSnackbar("No API that matches this file."));

      return;
    }

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: true,
    });

    const fromEcoEval =
      endpoint === "economic-evaluations" || endpoint.includes("prix");

    if (!fromPagination) {
      count = await dispatch(
        specificFilesCount(endpoint, whereArgs, fromEcoEval)
      );
    }

    if (count || fromPagination) {
      if (endpoint.includes("prix")) {
        await dispatch(action["pricing"]);
      } else {
        await dispatch(action[endpoint]);
      }
    }

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: false,
    });
  };
