import React, { Component } from "react";
import { connect } from "react-redux";

class CommunAlerte extends Component {
  render() {
    return (
      <>
        {this.props.successStatus && (
          <div className="settings__alerte-message success-message">
            <p className="alerte-message__text success-message__text">
              <i
                className="fa-solid fa-check"
                style={{ marginRight: "1rem" }}
              ></i>
              {this.props.message}
            </p>
          </div>
        )}

        {this.props.errorStatus && (
          <div className="settings__alerte-message error-message">
            <p className="alerte-message__text error-message__text">
              <i
                className="fa-solid fa-xmark"
                style={{ marginRight: "1rem" }}
              ></i>
              {this.props.message}
            </p>
          </div>
        )}
        {this.props.errorListStatus &&
          this.props.errorListMessage.map((element, index) => (
            <div className="settings__alerte-message error-message" key={index}>
              <p className="alerte-message__text error-message__text">
                <i
                  className="fa-solid fa-xmark"
                  style={{ marginRight: "1rem" }}
                ></i>
                {element}
              </p>
            </div>
          ))}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    successStatus: state.AlertReducer.success,
    errorStatus: state.AlertReducer.error,
    message: state.AlertReducer.message,
    errorListMessage: state.AlertReducer.errorListMessage,
    errorListStatus: state.AlertReducer.errorList,
  };
};

export default connect(mapStateToProps, {})(CommunAlerte);
