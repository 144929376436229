import { crudService } from "../../Config/CrudService";
import {
  ADD_FILE_CLINICAL_TRIAL,
  EDIT_FILE_CLINICAL_TRIAL,
  GET_CLINICATL_TRIALS_FILE_LIST,
  GET_SEARCHED_FILES_BY_COLUMN,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  LOADING_SEARCH_FILE,
  SET_DUPLICATION_ID,
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  VERIFY_NCT,
} from "../../Const/ActionTypes";
import { displayErroSnackbar, incrementCount } from "./CommunFilesAction";
import { setLoading, unsetLoading } from "./UIActions";

export const getClinicalTrials = () => {
  return (dispatch) => {
    crudService
      .get(`/ncts-infos`)
      .then((res) => {
        dispatch({
          type: GET_CLINICATL_TRIALS_FILE_LIST,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalClinicalTrialSubmit = (data, duplication = false) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/ncts-infos`, data)
      .then((res) => {
        dispatch({
          type: ADD_FILE_CLINICAL_TRIAL,
          payload: res.data,
        });

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche Clinical Trial est dupliquée avec succès ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche Clinical Trial est ajoutée avec succès ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.log(err);

        let errorMsg =
          err.response === undefined
            ? "Merci de réessayer ultérieurement , une erreur s'est produite de notre coté"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const globalClinicalTrialEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/ncts-infos/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_CLINICAL_TRIAL,
          payload: res.data,
        });

        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload:
            sectionName === undefined
              ? "La fiche Clinical Trial ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche Clinical Trial"
            : err.response.data.error.message;
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const nctNumberNotFound = (nctNumber) => (dispatch) => {
  const errorMsg = `Le NCT Number: ${nctNumber} n'est pas valide`;

  dispatch({
    type: SHOW_ERROR_MESSAGE,
    payload: errorMsg,
  });

  setTimeout(() => {
    dispatch({ type: HIDE_ERROR_MESSAGE });
  }, 2000);
};

export const verifyNCT = (nctNumber, callbackFunc) => (dispatch) => {
  // const customConfig = {
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // };

  crudService
    .post(`/ncts-infos-check`, nctNumber)
    .then((res) => {
      dispatch({
        type: VERIFY_NCT,
        payload: res.data,
      });

      if (callbackFunc) {
        callbackFunc();
      }
    })
    .catch((err) => {
      dispatch(nctNumberNotFound(nctNumber));

      dispatch({
        type: VERIFY_NCT,
        payload: false,
      });

      if (callbackFunc) {
        callbackFunc();
      }

      console.log("error message", err.message);
    });
};

export const resetVerifyNCT = () => (dispatch) => {
  dispatch({
    type: VERIFY_NCT,
    payload: null,
  });
};

export const searchClinicalTrials = (limit = 5, offset = 0, where = null) => {
  return async (dispatch) => {
    const filter = JSON.stringify(where);

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: true,
    });

    await crudService
      .get(`/ncts-infos?filter=${filter}&limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);

        dispatch(displayErroSnackbar(err));
      });

    dispatch({
      type: LOADING_SEARCH_FILE,
      payload: false,
    });
  };
};
