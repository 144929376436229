import {
  GET_HIRA_FILE_LIST,
  ADD_FILE_HIRA,
  EDIT_FILE_HIRA,
  DELETE_FILE_HIRA,
} from '../../Const/ActionTypes';

const initialState = {
  listHira: [],
 
};

export default function HiraReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HIRA_FILE_LIST: {
      return Object.assign({}, state, {
        listHira: action.payload,
      });
    }

    case ADD_FILE_HIRA: {
      return Object.assign({}, state, {
        listHira: [action.payload].concat(state.listHira),
       });
    }
    case EDIT_FILE_HIRA: {
      return Object.assign({}, state, {
        listHira: [
          action.payload,
          ...state.listHira.filter(element => element.id !== action.payload.id),
        ],
       });
    }
    case DELETE_FILE_HIRA: {
      return Object.assign({}, state, {
        listHira: [
          ...state.listHira.filter(element => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
