import { Provider } from "react-redux";
import configStore, { history } from "./Redux/configStore";
import { Route, Switch } from "react-router-dom";
import MainApp from "./Components/MainApp";
import { ConnectedRouter } from "connected-react-router";
import moment from "moment";
import { datadogRum } from "@datadog/browser-rum";

moment.updateLocale("language_code", {
  invalidDate: " ",
});
function App() {
  const store = configStore();

  if (process.env.REACT_APP_NODE_ENV !== "local") {
    try {
      datadogRum.init({
        applicationId: "5bdab841-f275-4a7f-853d-b9f03a73c6fc",
        clientToken: "pube669a9bb77d6095a0f9ad090a2bd4d26",
        site: "datadoghq.com",
        service: "prismaccess-backoffice",
        env: process.env.REACT_APP_NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
      });
      datadogRum.startSessionReplayRecording();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <div className="wrapper">
            <Switch>
              <Route path="/" render={(props) => <MainApp {...props} />} />
            </Switch>
          </div>
        </div>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
