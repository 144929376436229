import React, {Component} from 'react';
 
export default class RequiredFieldsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="action-popup action-popup-edit _active">
        <div className="action-popup__wrapper">
          <div className="action-popup__content">
            <p className="action-popup__text">{this.props.modalMessage}</p>
            <div className="action-popup__buttons">
              <button
                className="action-popup__button action-popup__button_continue button"
                onClick={this.props.handleCancel}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
