/* eslint import/no-anonymous-default-export: "off" */

import {
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE_LIST,
} from '../../Const/ActionTypes';

const initialState = {
  error: false,
  success: false,
  message: '',
  errorListMessage: [],
  errorList: false,
  sectionAlerte: '',
};
export default function (state = initialState, action) {
  if (action.type === SHOW_ERROR_MESSAGE) {
    return Object.assign({}, state, {
      message: action.payload,
      error: true,
    });
  }
  if (action.type === SHOW_ERROR_MESSAGE_LIST) {
    return Object.assign({}, state, {
      errorListMessage: action.payload,
      errorList: true,
    });
  }
  if (action.type === HIDE_ERROR_MESSAGE) {
    return Object.assign({}, state, {
      error: false,
      message: '',
      errorListMessage: [],
      errorList: false,
    });
  }
  if (action.type === SHOW_SUCCESS_MESSAGE) {
  

    return Object.assign({}, state, {
      message: action.payload,
      success: true,
      sectionAlerte: action.sectionAlerte,
    });
  }
  if (action.type === HIDE_SUCCESS_MESSAGE) {
    return Object.assign({}, state, {
      success: false,
      message: '',
      sectionAlerte: '',
    });
  }

  return state;
}
