/* eslint import/no-anonymous-default-export: "off" */

import {
  EDIT_FILE_BOITE_PRESENTATION,
  GET_BOITE_PRESENTATION_FILE_LIST,
  ADD_FILE_BOITE_PRESENTATION,
  DELETE_FILE_BOITE_PRESENTATION,
} from '../../Const/ActionTypes';

const initialState = {
  listBoitePresentation: [],
 };

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BOITE_PRESENTATION_FILE_LIST: {
      return Object.assign({}, state, {
        listBoitePresentation: action.payload,
      });
    }
    case EDIT_FILE_BOITE_PRESENTATION: {
      return Object.assign({}, state, {
        listBoitePresentation: [
          action.payload,
          ...state.listBoitePresentation.filter((element) => element.id !== action.payload.id),
        ],
       });
    }
    case ADD_FILE_BOITE_PRESENTATION: {
      return Object.assign({}, state, {
        listBoitePresentation: [action.payload].concat(state.listBoitePresentation),
       });
    }
    case DELETE_FILE_BOITE_PRESENTATION: {
      return Object.assign({}, state, {
        listBoitePresentation: [
          ...state.listBoitePresentation.filter((element) => element.id !== action.payload),
        ],
      });
    }
    default:
      return state;
  }
}
