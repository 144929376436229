/* eslint import/no-anonymous-default-export: "off" */

  import {
    GET_LIST_HTAROADMAP,
    ADD_HTAROADMAP,
    EDIT_HTAROADMAP,
    DELETE_FILE_HTAROADMAP
  } from "../../Const/ActionTypes";
  
  const initialState = {
    listHtaRoadMap: [],
    
  };
  
  export default function htaRoadMap(state = initialState, action) {
    switch (action.type) {
      case GET_LIST_HTAROADMAP: {
        return Object.assign({}, state, {
          listHtaRoadMap: action.payload,
        });
      }
      case EDIT_HTAROADMAP: {
        return Object.assign({}, state, {
          listHtaRoadMap: state.listHtaRoadMap.map((element) =>
            element.id === action.payload.id ? action.payload : element
          ),
  
         });
      }
      case ADD_HTAROADMAP: {
        return Object.assign({}, state, {
          listHtaRoadMap: [action.payload].concat(state.listHtaRoadMap),
         });
      }
  
      case DELETE_FILE_HTAROADMAP: {
        return Object.assign({}, state, {
          listHtaRoadMap: [
            ...state.listHtaRoadMap.filter(
              (element) => element.id !== action.payload
            ),
          ],
        });
      }
      default:
        return state;
    }
  }