/* eslint import/no-anonymous-default-export: "off" */

import { GET_LIST_IQWIG_EXTENT_PROBABILITIE } from "../../Const/ActionTypes";

const initialState = {
  iqwigExtentProbabilitieList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_IQWIG_EXTENT_PROBABILITIE: {
      return Object.assign({}, state, {
        iqwigExtentProbabilitieList: action.payload,
      });
    }

    default:
      return state;
  }
}
