import {
  ADD_FILE_PRICING_SPAIN,
  DELETE_FILE_PRICING_SPAIN,
  EDIT_FILE_PRICING_SPAIN,
  GET_PRICING_SPAIN_FILE_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  listPricingSpain: [],
  
};

export default function pricingSpain(state = initialState, action) {
  switch (action.type) {
    case GET_PRICING_SPAIN_FILE_LIST: {
      return Object.assign({}, state, {
        listPricingSpain: action.payload,
      });
    }
    case EDIT_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: state.listPricingSpain.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),

       });
    }
    case ADD_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: [action.payload].concat(state.listPricingSpain),
       });
    }

    case DELETE_FILE_PRICING_SPAIN: {
      return Object.assign({}, state, {
        listPricingSpain: [
          ...state.listPricingSpain.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
