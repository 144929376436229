import React from "react";
import AgencyLine from "./AgencyLine";

function Region({
  regionName,
  values,
  handleChangeOpenRegion,
  handleChangePermissions,
  handleGlobalAction,
}) {
  return (
    <div>
      <div className="form-settings__columns form-settings__columns_start">
        <div
          className="form-settings__column"
          style={{ width: "150px", cursor: "pointer" }}
          onClick={(e) => handleChangeOpenRegion(regionName)}
        >
          <strong>
            {regionName}{" "}
            <i
              className={
                values.openRegions[regionName]
                  ? "fas fa-chevron-up"
                  : "fas fa-chevron-down"
              }
              style={{ marginLeft: "15px" }}
            />
          </strong>
        </div>
        <div className="form-settings__column checkbox_container">
          <input
            className="checkbox _icon-checkbox"
            type="checkbox"
            checked={values.permissions.every((e) => {
              if (e.region === regionName) {
                return e.region === regionName && e.create;
              } else {
                return true;
              }
            })}
            onChange={(e) => {
              handleGlobalAction(regionName, "create", e);
            }}
          />
        </div>
        <div className="form-settings__column checkbox_container">
          <input
            className="checkbox _icon-checkbox"
            type="checkbox"
            onChange={(e) => {
              handleGlobalAction(regionName, "update", e);
            }}
            checked={values.permissions.every((e) => {
              if (e.region === regionName) {
                return e.region === regionName && e.update;
              } else {
                return true;
              }
            })}
          />
        </div>
        <div className="form-settings__column checkbox_container">
          <input
            className="checkbox _icon-checkbox"
            type="checkbox"
            onChange={(e) => {
              handleGlobalAction(regionName, "duplicate", e);
            }}
            checked={values.permissions.every((e) => {
              if (e.region === regionName) {
                return e.region === regionName && e.duplicate;
              } else {
                return true;
              }
            })}
          />
        </div>
        <div className="form-settings__column checkbox_container">
          <input
            className="checkbox _icon-checkbox"
            type="checkbox"
            onChange={(e) => {
              handleGlobalAction(regionName, "delete", e);
            }}
            checked={values.permissions.every((e) => {
              if (e.region === regionName) {
                return e.region === regionName && e.delete;
              } else {
                return true;
              }
            })}
          />
        </div>
      </div>
      {values.openRegions[regionName] &&
        values.permissions
          .filter((permission) => permission.region === regionName)
          .map((agency, key) => (
            <AgencyLine
              key={key}
              agencyItem={agency}
              handleChangePermissions={handleChangePermissions}
            />
          ))}
    </div>
  );
}

export default Region;
