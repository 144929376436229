/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_LIST_DISEASE,
  ADD_DISEASE,
  EDIT_DISEASE,
  DELETE_DISEASE,
  GET_LIST_DISEASE_PAGINATED,
} from "../../Const/ActionTypes";

const initialState = {
  listDisease: [],
  listDiseasePaginated: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIST_DISEASE: {
      return Object.assign({}, state, {
        listDisease: action.payload,
      });
    }
    case GET_LIST_DISEASE_PAGINATED: {
      return Object.assign({}, state, {
        listDiseasePaginated: action.payload,
      });
    }
    case ADD_DISEASE: {
      return Object.assign({}, state, {
        listDisease: [action.payload].concat(state.listDisease),
        listDiseasePaginated: [action.payload].concat(
          state.listDiseasePaginated
        ),
      });
    }
    case EDIT_DISEASE: {
      return Object.assign({}, state, {
        listDisease: state.listDisease.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
        listDiseasePaginated: state.listDiseasePaginated.map((element) =>
          element.id === action.payload.id ? action.payload : element
        ),
      });
    }
    case DELETE_DISEASE: {
      return Object.assign({}, state, {
        listDisease: [
          ...state.listDisease.filter(
            (element) => element.id !== action.payload
          ),
        ],
        listDiseasePaginated: [
          ...state.listDiseasePaginated.filter(
            (element) => element.id !== action.payload
          ),
        ],
      });
    }
    default:
      return state;
  }
}
