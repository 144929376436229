import React from "react";
import Styles from "./Spinner.module.css";

function SpinnerLogOut() {
  return (
    <div className={Styles["loader-wrapper"]}>
      <div className={Styles["loader-roller"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={Styles["loader-text"]}>
        <h1>Disconnecting..</h1>
      </div>
    </div>
  );
}

export default SpinnerLogOut;
