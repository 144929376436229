import {
  GET_LIST_DISEASE,
  ADD_DISEASE,
  EDIT_DISEASE,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  DELETE_DISEASE,
  UPDATE_SEARCHED_FILES,
} from "../../Const/ActionTypes";

import { setLoading, unsetLoading } from "./UIActions";
import { decrementCount, incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const addDisease = (data) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/indexations`, data)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: ADD_DISEASE,
            payload: res.data,
          });
          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: "La création  est effectuée avec succès",
          });

          dispatch(incrementCount());

          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        } else {
          let errorMsg =
            res.response?.data?.error?.message ??
            "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";

          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: errorMsg,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg = err.response
          ? err.response.data?.error?.message
          : "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getDiseaseList = () => {
  return async (dispatch) => {
    let filter = JSON.stringify({
      fields: {
        id: true,
        index_en: true,
        index_de: true,
        index_fr: true,
      },
      order: ["id DESC"],
    });

    await crudService
      .get(`/indexations?filter=${filter}`)
      .then((res) => {
        dispatch({
          type: GET_LIST_DISEASE,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const editDisease = (data) => {
  return (dispatch) => {
    crudService
      .patch(`/indexations/` + data.id, data)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: EDIT_DISEASE,
            payload: res.data,
          });
          dispatch({
            type: UPDATE_SEARCHED_FILES,
            payload: res.data,
          });

          dispatch({
            type: SHOW_SUCCESS_MESSAGE,
            payload: "La modification est effectuée avec succès",
          });
          setTimeout(() => {
            dispatch({ type: HIDE_SUCCESS_MESSAGE });
          }, 4000);
        } else {
          let errorMsg =
            res.response?.data?.error?.message ??
            "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";

          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: errorMsg,
          });
          setTimeout(() => {
            dispatch({ type: HIDE_ERROR_MESSAGE });
          }, 4000);
        }
      })
      .catch((err) => {
        let errorMsg = err.response
          ? err.response.data?.error?.message
          : "Merci  de réessayer ultérieurement , une erreur s'est produite de notre coté";
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      });
  };
};

export const deleteDisease = (id) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .remove(`/indexations/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_DISEASE,
          payload: id,
        });
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: "La suppression a été effectuée avec succès",
        });

        dispatch(decrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
        window.scrollTo(0, 0);
      });
  };
};
