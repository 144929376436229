import {
  LOADING_GET_PROFILE,
  SET_LOADING,
  SET_LOADING_FIND_BY_ID,
  UNSET_LOADING,
  UNSET_LOADING_FIND_BY_ID,
  LOADING_LOG_OUT,
  LOADING_SEARCH_FILE,
  LOADING_SELECT_INPUTS,
} from "../../Const/ActionTypes";

const initialState = {
  loading: false,
  loadingFindById: false,
  loadingUser: false,
  loadingLogOut: false,
  loadingSearch: false,
  loadingSelectInputs: false,
};

function UIReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_LOG_OUT: {
      return Object.assign({}, state, {
        loadingLogOut: action.payload,
      });
    }
    case SET_LOADING: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case SET_LOADING_FIND_BY_ID: {
      return Object.assign({}, state, {
        loadingFindById: true,
      });
    }

    case UNSET_LOADING_FIND_BY_ID: {
      return Object.assign({}, state, {
        loadingFindById: false,
      });
    }

    case UNSET_LOADING: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case LOADING_GET_PROFILE: {
      return {
        ...state,
        loadingUser: action.payload,
      };
    }

    case LOADING_SEARCH_FILE: {
      return {
        ...state,
        loadingSearch: action.payload,
      };
    }

    case LOADING_SELECT_INPUTS: {
      return {
        ...state,
        loadingSelectInputs: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export default UIReducer;
