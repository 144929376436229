/* eslint import/no-anonymous-default-export: "off" */

import {
  GET_USER_OPTIONS,
  EDIT_USER_OPTIONS,
  GET_USER_LIST,
} from "../../Const/ActionTypes";

const initialState = {
  userOptions: {
    id: undefined,
    userId: undefined,
  },
  usersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: action.payload,
      });
    }
    case EDIT_USER_OPTIONS: {
      return Object.assign({}, state, {
        userOptions: { ...state.userOptions, ...action.payload },
      });
    }
    case GET_USER_LIST: {
      return Object.assign({}, state, {
        usersList: action.payload,
      });
    }
    default: {
      return state;
    }
  }
}
